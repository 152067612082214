import {mapGetters} from 'vuex'

export default {
  computed: {
    showPrice () {
      if (this.config_app.roles_apply_hide_price.includes(this.role) && !this.user.can_see_prices) {
        return false
      }
      return true
    },
    showTotals () {
      if (this.config_app.roles_apply_hide_totals.includes(this.role) && !this.user.can_see_totals) {
        return false
      }
      return true
    },
    config_app () {
      return this.$config.configApp
    },
    user () {
      return this.$store.getters.user
    },
    ...mapGetters([
      'role'
    ])
  }
}
