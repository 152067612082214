<template lang="pug">
  .pedido-cn
    .container(v-if="messageError")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{messageError}}
    div(v-if="!messageError && number")
      .box-search
        .container
          .row
            .col-sm-12 &nbsp;
              router-link( :to="{ name: 'catalogGeneratorList'}" ) #[translate Volver]
      .box-1
        .container
          .row(v-if="type_app !== 'pedrosa'")
            .col-sm-12
              span #[translate Fecha de creación del catálogo]  
                strong {{fecha | moment("DD/MM/YYYY") }}&nbsp;            
              strong(v-if="type_app !== 'pedrosa'")  #[translate Tipo]:&nbsp;
              span {{ typeName }}

          .row(v-if="type_app === 'pedrosa'")    
            .col-sm-12
              span #[translate Fecha de creación de la oferta]  
                strong {{fecha | moment("DD/MM/YYYY") }}&nbsp;&nbsp;
              span  #[translate Tipo de Portada]:&nbsp;
              strong {{ typeName }}&nbsp;
              span  #[translate Tipo de Página]:&nbsp;
              strong {{ items_per_page_name }}

      .container.table-1
        .row.table-head
          .col-sm-4
            h3 #[translate Centro]
          .col-sm-8
            h3 #[translate Cliente]

        .row.table-grid
          .col-sm-4
            .bg-gray
              p {{center.name}}
              p {{center.address}}
              p ({{center.postal_code}}, {{center.locality}})
          .col-sm-8
            .bg-gray
              p {{oClient.name}}
              p #[translate Cod]. {{oClient.external_code}}
              p &nbsp;

      .container.table-2
        .row.table-head(v-if="type !== 'budgetwof'")
          div.col-sm-7 {{numItems}} #[translate productos]
          .col-sm-3(v-if="type_app === 'pedrosa'") #[translate Ref.]
          .col-sm-3(v-else) #[translate Código]
          .col-sm-2.text-right
            div(v-if="type !== 'rwopawd' && type !== 'cwopawd'") #[translate Precio]
            div(v-else) &nbsp;
        
        .row.table-head(v-else)
          div.col-sm-5 {{numItems}} #[translate productos]
          .col-sm-1(v-if="type_app === 'pedrosa'")  #[translate Ref.]
          .col-sm-1(v-else)  #[translate Código]
          .col-sm-1 #[translate Formato]
          .col-sm-1.text-center #[translate Cantidad]
          .col-sm-2.text-right #[translate Precio Formato]
          .col-sm-2.text-right #[translate Precio]
      
        .table-grid
          bk-item(v-for="item in aProduct" :key="item.id" :entries="item" :type="type")

      .box-3(v-if="type === 'budgetwof'")
        .container
          .row
            .col-sm-8
            .col-sm-4
              ul
                li #[translate Subtotal]: #[small {{base | format-money}} {{moneda}}]
                li(v-if="client_discount_percentage>0") #[translate Descuento]: #[small {{client_discount_percentage | format-money}}%]
                li(v-if="client_discount_percentage>0") #[translate Importe final]: #[small {{final | format-money}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}] 
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}] 
          

      .box-4
        .container
          .row(v-if="type_app === 'pedrosa' && details")
            .col-sm-12.details 
                p #[translate Observaciones]: {{ details }}
          .row
            .col-sm-3
                button.btn.btn-cancel.ml-0(@click="goBack()") #[translate Volver]
            .col-sm-4(v-if="type_app !== 'pedrosa'")
              .dropdown.Select-bk
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{optionCatalog}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddPortes")
                  li(v-for="(value, key)  in catalogs_types" :class="{'disabled': optionCatalogId == key}")
                    span(:value="value" @click="changeOptionCatalogs(key, value)") {{value}}
              button.btn.btn-blue(@click="sendCatalogPDF()" :class="{'disabled': !optionCatalogId}") #[translate Enviar PDF]

            .col-sm-4(v-if="type_app === 'pedrosa'")              
              span #[translate Volver a enviar la oferta]
              button.btn.btn-blue(@click="sendCatalogPDF()") #[translate Enviar PDF]

            .col-sm-5.text-right
              span(v-if="type_app !== 'pedrosa'") #[translate Enviar catálogo a otro cliente ]:
              span(v-if="type_app === 'pedrosa'") #[translate Enviar oferta a otro cliente ]:
              button.btn.btn-orange(@click="selectClient()" data-toggle="modal" data-target="#modal-oferta" class="btn-modal" )  #[translate Seleccionar un cliente]
      bk-modal-oferta(ref="modalOferta" :products="aProduct")

    .info-pedido-ok(v-if="messageShow" class="fade in" :class="{'error': messageError}")
      div(v-if="messageError !== 1")
        // cuando se genera catálogo.
        p
          i(class="fa fa-check" aria-hidden="true")
        p(v-if="type_app !== 'pedrosa'")
          strong #[translate El catálogo se ha enviado con éxito].
        p(v-if="type_app === 'pedrosa'")
          strong #[translate La oferta se ha enviado con éxito].

        // p #[translate Nº de catálogo: 21724]
        p(v-if="type_app !== 'pedrosa'") #[translate Puede ver el historial de catálogos en]
          router-link( :to="{ name: 'catalogGeneratorList'}" class="" ) #[translate Mis catálogos]
        p(v-if="type_app === 'pedrosa'") #[translate Puede ver el historial de ofertas en]
          router-link( :to="{ name: 'catalogGeneratorList'}" class="" ) #[translate Mis Ofertas]

        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]
</template>
<script>
import BkItem from '@/components/catalogpdf/Item-single.vue'
import BkModalOferta from '@/components/catalogpdf/Modal-content.vue'
import SrvCatalogGenerator from '@/services/cataloggenerator'

export default {
  name: 'CatalogGeneratorSingle',
  props: ['id'],
  components: {BkModalOferta, BkItem},
  data () {
    return {
      number: null,
      fecha: null,
      tipo: null,
      oClient: null,
      center: null,
      state: null,
      base: null,
      client_discount_percentage: null,
      final: null,
      tax: null,
      total: null,
      moneda: null,
      details: null, // descripción
      numItems: null,
      messageError: null,
      messageShow: null,
      aProduct: [],
      optionCatalog: this.$gettext('Selecciona'),
      optionCatalogId: 0,
      catalogName: null,
      optionsCatalogs: [],
      type: null,
      typeName: null,
      items_per_page_name: null
    }
  },
  created () {
    this.getCatalogGenerator()
    if (this.config_app.catalog_generator) {
      this.optionsCatalogs = this.$store.getters.cart.catalogs_types
    }
  },
  computed: {
    type_app () {
      return this.$config.type
    },
    client () {
      return this.$store.getters.client
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    cart () {
      return this.$store.getters.cart
    },
    catalogs_types () {
      return this.cart.catalogs_types
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  methods: {
    changeOptionCatalogs (id, name) {
      // console.log(`----- ${v}`)
      this.optionCatalog = name
      this.optionCatalogId = id
    },
    selectClient () {
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      // this.$refs.showModal.click()
      this.$refs.modalOferta.openModal(1)
    },
    getCatalogGenerator () {
      // console.log('id: ' + this.id)
      SrvCatalogGenerator.getCatalogGenerator(this.id)
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            // console.log(`Pedido single: `, data)
            this.number = data.id
            this.fecha = data.created
            this.oClient = data.client
            this.center = data.center
            this.state = data.state
            this.base = data.base
            this.tax = data.tax
            this.total = data.total
            this.client_discount_percentage = data.client_discount_percentage
            this.final = data.final
            this.moneda = data.currency_symbol
            this.aProduct = data.products
            this.numItems = this.aProduct.length
            this.details = data.details
            this.type = data.type
            this.typeName = data.type_name
            this.items_per_page_name = data.items_per_page_name
            // console.log(`number `, res)
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    goBack () {
      this.$router.go(-1)
    },
    saveTemplate () {
      // TODO: funcionalidad guardar template, pendiente de API.
      // console.log('Guardar plantilla')
    },
    sendCatalogPDF () {
      this.enviando = true
      // console.log(`Envio Oferta: ${this.optionId}`)
      SrvCatalogGenerator.setCatalogGeneratorPDF(this.optionCatalogId, this.number)
        .then(res => {
          // console.log(`------> respuesta API finalización oferta: `, res)
          if (res.status === 200) {
            this.messageShow = true // this.$gettext('Oferta realizada con exito.')
            window.scrollTo(0, 0)
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
          this.enviando = false
        }, (error) => {
          // console.log('----->', error)
          if (error.status === 104) {
            this.messageShow = true
            this.messageError = 1 // this.$gettext('Está oferta supera el límite de consumo asignado. La oferta pasa a estar pendiente de validación.')
            // borramos carrito
            this.$store.dispatch('deleteCart')
            // actualizamos el valor de las ofertas pendientes.
            this.$store.dispatch('updateValidableOffers')
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          window.scrollTo(0, 0)
          this.enviando = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-search{
    padding: 14px 0;
    background-color: #EFEFEF;
  }

  .alert{
    margin-top: 40px;
  }

  .state-cn{
    color: $orange;
    &.SERVIDO, &.S{
      color: $green;
    }
  }

  .box-1{
    padding: 26px 0 20px;
    font-size: 16px;
    small{
      font-size: 16px;
    }

    a{
      color: $blue-light;
    }
  }

  .info-pedido-ok{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e6f7d4;
    z-index: 10;
    min-height: 700px;

    &.error{
      background-color: #FFF1D3;
    }
    & > div{
      padding: 100px 40px 40px;
    }

    p{
      text-align: center;
      font-size: 22px;
      color: #5c6783;
      font-weight: $light;
      margin-bottom: 30px;
    }

    i{
      color: #ace174;
      border: 3px solid #ace174;
      @include roundAll100(50%);
      width: 70px;
      height: 70px;
      line-height: 64px;
      font-size: 40px;

      &.orange-ico{
        color: #FEC731;
        border-color: #FEC731;
      }
    }

    a{
      display: inline-block;
      margin-left: 6px;
    }

    strong{
      color: #2b569b;
      font-size: 30px;
      font-weight: $light;
    }
  }

  .table-1{
    .table-head{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;

      .holder-cn{
        width: 32%;

        &:nth-child(3){
          h3 {
            font-weight: $medium;
          }
        }
      }

      h3{
        border-bottom: 1px solid #1C2C40;
        font-size: 14px;
        font-weight: $regular;
        padding-bottom: 6px;
      }

      p{
        margin: 0;
      }
    }

    .table-grid{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;
      font-size: 14px;
      color:#4D4F5C;
      .bg-gray{
        background-color: #F1F4F7;
        padding: 20px 18px;
      }
      .holder-cn{
        width: 32%;
        padding: 20px 18px;
        background-color: #F1F4F7;
      }

      strong{
        min-width: 130px;
        display: inline-block;
        font-weight: $regular;
      }
    }
  }

  .table-2{
    .table-head{
      margin: 6px 0;
      & > *{
        padding-bottom: 6px;
        border-bottom: 1px solid #979797;
        font-size: 14px;

        &:first-child{
          font-weight: $medium;
        }
      }

      p{
        margin: 0;
      }
    }
  }

  .box-3{
    margin:24px 0 30px;

    .row{
      border-bottom: 1px solid #1C2C40;
      padding-bottom:16px;
    }

    ul{
      padding-left: 0;
      list-style: none;
    }

    li{
      color: #5b5d5e;
      font-size: 14px;
      margin-bottom: 14px;

      small{
        font-size: 14px;
        float: right;
      }

      &:last-child{
        font-size: 14px;

        small{
          font-size: 16px;
          font-weight: $medium;
        }
      }
    }

    .obser-cn{
      strong{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
      }

      small{
        display: inline-block;
        font-size: 14px;
        width:70%;
        height: 80px;
      }
    }
  }

  .box-4{
    margin-top:25px;

    p{
      font-size: 18px;
      color: #494f58;
    }

    .details{
      margin-bottom:15px;
      p{
        font-size: 14px;
        color:#A4A59D;
      }
    }

    input[type="text"]{
      max-width: 328px;
      display: inline-block;
      padding-top: 20px;
      padding-bottom: 22px;
      font-size: 16px;
      vertical-align: middle;
      margin-right: 10px;
    }

    .btn{
      font-size: 16px;
      font-weight: $regular;
      margin-left: 20px;
    }
    .btn-blue{
      background-color: #00ADFC;
      color:#fff;
      margin-left:20px;
    }
    .btn-orange{
      background-color: #FF8054;
    }
    .Select-bk.dropdown{
      width:200px;
      .dropdown-menu {
        max-height: 140px;
      }
      .dropdown-toggle {
        height: 45px;
        line-height: 45px;
        i {
          line-height: 45px;
        }
      }
    }
    .ml-0{
    margin-left: 0;
  }
  }
</style>
