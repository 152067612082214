import { render, staticRenderFns } from "./Item-single.vue?vue&type=template&id=109d4d26&scoped=true&lang=pug"
import script from "./Item-single.vue?vue&type=script&lang=js"
export * from "./Item-single.vue?vue&type=script&lang=js"
import style0 from "./Item-single.vue?vue&type=style&index=0&id=109d4d26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "109d4d26",
  null
  
)

export default component.exports