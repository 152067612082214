<template lang="pug">
  .CenterItemSimple.container
    .row.box-1(:class="{'par': index%2}")
      .col-sm-5
        p
          span.img-cn.tw-flex(data-toggle="collapse" :data-target="'#clients-'+index")
            img(:src="img.derecha" width="8")
          span.name(@click="setCenter" data-dismiss="modal") {{center.name}}
      .col-sm-1.text-center(@click="setCenter" data-dismiss="modal")
        p(v-if="type_app !== 'orbishigiene' && type_app !== 'ghgorbis' && type_app !== 'arablau'") {{center.external_code}}
      .col-sm-3(@click="setCenter" data-dismiss="modal")
        p.adress {{center.address}}
      .col-sm-3(@click="setCenter" data-dismiss="modal")
        p.loca {{center.locality}} - {{center.province}}
    .row.box-2(:id="'clients-'+index" class="collapse")
      .col-sm-1 &nbsp;
      .col-sm-7
        p #[translate Persona de contacto]: #[strong {{checkEmpty(center.contact)}}]
        p #[translate Teléfono]: #[strong {{checkEmpty(center.phone)}}]
        p #[translate Horario]: #[strong {{center.morning_open_times}} - {{center.afternoon_open_times}}]
        p #[translate Observaciones]: #[strong {{checkEmpty(center.observations)}}]
      .col-sm-4
        p #[translate Limite de compra]: #[strong {{checkEmpty(center.purchase_limit)}}]
        p #[translate Periodo límite de compra]: #[strong {{limitPeriod(center.purchase_limit_period)}}]
</template>
<script>
// importamos servicios Center
import SrvCenter from '@/services/center'

export default {
  name: 'CenterListItemSimple',
  props: ['center', 'index'],
  data () {
    return {
      img: {
        derecha: require('@/assets/img/icos/derecha.svg')
      }
    }
  },
  created () {
    // console.log('', this.center)
  },
  computed: {
    type_app () {
      return this.$config.type
    }
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    setCenter () {
      SrvCenter.getCenter(this.center.id)
        .then(res => {
          // console.log(`center seleccionado: `, res)
          if (res.status === 200) {
            // Enviamos a Store Center los datos del centro que recibimos con el has_facturable
            this.$store.dispatch('setCenter', res.data)
            // Lo pasamos a su State
            this.$store.commit('SET_CENTER')
          }
        }, () => {
          // console.log('----->' + error)
          // Enviamos a Store Center los datos del centro seleccionado
          this.$store.dispatch('setCenter', this.center)
          // Lo pasamos a su State
          this.$store.commit('SET_CENTER')
        })
    },
    limitPeriod (v) {
      switch (v) {
        case 'X':
          v = this.$gettext('Sin límite')
          break
        case 'M':
          v = this.$gettext('Mensual')
          break
        case 'T':
          v = this.$gettext('Trimestral')
          break
        case 'S':
          v = this.$gettext('Semestral')
          break
        case 'A':
          v = this.$gettext('Anual')
          break
        default:
          v = '-'
      }

      return v
    }
  }
}
</script>
<style lang="scss" scoped>
.CenterItemSimple{
  color:#494f58;
}
.box-1{
  @include transition();
  font-size: 14px;
  color: #4D4F5C;
  font-weight: $regular;
  border:1px solid #fff;
  cursor: pointer;

  & > *{
    padding-top:12px;
    padding-bottom:8px;
  }

  &:hover, &:focus{
    background-color: rgba(#129FE9, .2);
    border:1px solid #00ADFC;

    .name{
      font-weight: $medium;
    }
  }

  p{
    margin:0;
  }
}
.name, .adress, .loca{
  text-transform: uppercase;
  @include ellipsis();
  cursor: pointer;
  // margin-left:-15px;
}

.name{
  margin-left: 34px;
  display:block;
  @include transition();
}

.img-cn{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  height: 22px;
  margin: auto;
  padding-right: 10px;

  img{
    margin-right: 14px;
    @include transition();
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

[aria-expanded="true"]{
    img{
      -moz-transform: rotate(90deg) translate(0px);
      -o-transform: rotate(90deg) translate(0px);
      -ms-transform: rotate(90deg) translate(0px);
      -webkit-transform: rotate(90deg) translate(0px);
      transform: rotate(90deg) translate(0px);
    }
}

.box-2{
  padding: 16px 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #F5F6FA;
  color: #4D4F5C;
  font-weight: $light;

  strong{
    font-weight: $regular;
  }
}
</style>
