import SrvCatalog from '@/services/catalog'

export default {
    methods: {
        async finishUpdateOffer () {
            this.messageUpdateOfferError = null
            this.messageUpdateOfferShow = null
            let error = false
            if (!this.enviando) {
              this.enviando = true
              if (!this.lead) {
                this.controlForCenter()
              }
              try {
                const res = await SrvCatalog.updateOffer(this.observations, this.printFormatsId)
                if (res.status === 200) {
                  this.messageUpdateOfferShow = true
                  window.scrollTo(0, 0)
                  this.$store.dispatch('cleanEditOfferPending')
                } else {            
                    this.messageUpdateOfferError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
                    window.scrollTo(0, 0)
                }
              } catch(e) {
                console.log(e)
                if (e.data.code === '102') {
                  window.scrollTo(0, 0)
                  this.messageError = e.data.message
                }
              }
              this.enviando = false
            }
          },
    }
}