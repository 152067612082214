<template lang="pug">
    button {{text}}
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Generar Pedido Recurrente',
        }
    }
}
</script>
<style scoped>
button{
    color:#fff;
    background-color: #64B77F;
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    margin-left: 30px;
}
</style>