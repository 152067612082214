<template lang="pug">
  #buyItemPopup(class="modal fade" :class="classOpen" role="dialog")
    .modal-dialog.modal-lg
      .modal-content(v-if="product")
        .modal-header
          .row-flex
            h2 {{product.name}}
            button(type="button" class="close" data-dismiss="modal")
              img(:src="img.close" width="12")
        .modal-body
          form(@submit.prevent="submit()")
            .row
              .col-sm-5
                .holder-img( :class="{'type-outlet': product.is_outlet, 'type-eco': product.is_eco}")
                  small(v-if="product.is_eco") #[translate Eco]
                  small(v-if="product.is_outlet") #[translate Outlet]
                  img(:src="productImg")
                  tag-soste(v-if="appConfig.featureFlags.infoSoste && product.sustainability_index" :hover="false" :slim="true" :index="product.sustainability_index")
                  bk-favorite(class="favorite-img" :data-id="product.id")
              .col-sm-7
                .descripction-cn(v-if="description") {{description}}
                div.box-info-popup
                  ul.info-cn
                    li {{product.external_id}}
                    li {{product.quantity_by_packet}} #[translate unidades por envase]
                    li {{product.packets_by_package}} #[translate envases por paquete]

                  .info-addCart(v-show="product.price !== null")
                      p.pvp(v-if="showPrice") #[translate Precio €/unidad:] #[strong {{price | format-money}} {{product.currency_symbol}} ]
                      div.und(:class="{'noStock': !product.stock, 'normalStock': config_app.stock_availability}")
                        p(v-if="config_app.stock_availability") to be delisted XXXX
                        p(v-else-if="config_app.stock && !product.is_network") {{product.stock}} #[translate en stock]
                        p(v-else) &nbsp;
                      div
                        .formato(v-if="config_app.formato && aFormato.length > 0")
                          label #[translate Formato]
                          .dropdown.Select-bk
                            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddFormato" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{formatoItem.name}} ({{formatoItem.quantity}})
                              i(class="fa fa-angle-down" aria-hidden="true")
                            ul(class="dropdown-menu" aria-labelledby="ddFormato")
                              li(v-for="item in aFormato" :class="{'disabled': formatoItem.id == item.id}")
                                span(:value="item.id" @click="changeFormato(item)") {{item.name}}
                                  span(v-if="item.id") &nbsp;#[translate de] {{item.quantity}} #[translate uds].

                        .cant
                          label #[translate Cantidad]
                          input(type="number" placeholder="0" min="0" :max="limitQuantity" v-model="quantity" @keyup="checkQuanty")
                        p.pvpTotal
                          span(v-if="showPrice && total" :class="{'special':specialPriceType, 'no-stock': !product.stock, 'obsolete': product.obsolete}" ) {{total | format-money}} {{product.currency_symbol}}
                          span(v-else) &nbsp;

                  .cotiza-single(v-show="product.price === null" :product="product" type="single")
                    p
                      img(:src="img.cotiza_single" width="42" alt="" class="img-cn")
                    p #[translate ¿Quieres solicitar un presupuesto de este producto?]
                    p
                      button.btn(@click="callCotiza()"  data-dismiss="modal")
                        translate Pedir presupuesto

            .box-ft
              p.doc-cn
                a(:href="product.doc_technical_url" target="_blank" class="doc-link" v-if="product.doc_technical_url")
                  div.tw-flex.tw-justify-center.tw-items-center
                    img(:src="img.docDownload" width="13" alt="")
                    span #[translate Ficha Técnica]
                a(:href="product.doc_security_url" target="_blank" class="doc-link" v-if="product.doc_security_url")
                  div.tw-flex.tw-justify-center.tw-items-center
                    img(:src="img.docDownload" width="13" alt="")
                    span #[translate Ficha Seguridad]

              p.submit(v-if="!hiddenAddCart && (isClient || isLead)")
                button(type="button" class="btn btn-orange" @click="toAddCart()" data-dismiss="modal" ref="btnSubmit") #[translate Añadir al Pedido]
</template>

<script>
import {mapGetters} from 'vuex'
import commonMixins from '@/mixins/commons.js'

import BkFavorite from '@/components/common/favorite.vue'
import TagSoste from '@/components/catalog/Tag-Soste.vue'
// importamos servicios Catálogo
import SrvCatalog from '@/services/catalog'
import appConfig from '@/config-app.js'
export default {
  name: 'buyItemPopup',
  mixins: [ commonMixins ],
  props: ['classOpen'], // clase para abrir el popup
  components: { BkFavorite, TagSoste },
  data: () => ({
    appConfig: appConfig,
    'id': {},
    'description': '',
    'brand': '',
    'product': null,
    'quantity': 1,
    'itemsCart': [], // productos que tiene el carro.
    'formatoItem': null,
    'aFormato': [],
    'img': {
      'docDownload': require('@/assets/img/ficha/doc-download.svg'),
      'close': require('@/assets/img/icos/cerrar-01.svg'),
      'cotiza_single': require('@/assets/img/icos/cotiza_single.svg')
    },
    specialPriceType: false
  }),
  created () {
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    total () {
      if (this.formatoItem) {
        return this.price * this.quantity * this.formatoItem.quantity
      }
      return this.price * this.quantity
    },
    price () {
      let precio = this.product.price
      if (this.product.special_price !== null) {
        precio = this.product.special_price
        this.specialPriceType = true
        if (this.type_app === 'pedrosa' && this.role === 'client') {
          this.specialPriceType = false
        }
      }
      return precio
    },
    center () {
      return this.$store.state.center.center
    },
    isClient () {
      return this.$store.getters.isClient
    },
    isLead () {
      return this.$store.getters.isLead
    },
    hiddenAddCart () {
      // si  no tiene precio lo ocultamos
      if (this.product.price === null) {
        return true
      }
      // Si no hay stock y tiene activado el limite, ocultamos el botón de comprar.
      if (!this.product.stock && this.config_app.stock_limit) {
        return true
      }
      // Ocultamos el botón de añadir el carrito cuando no tengamos stock, obsolete_limit: true y obsolete: true
      if (!this.product.stock && this.product.obsolete && this.config_app.obsolete_limit) {
        return true
      }
      return false
    },
    limitQuantity () {
      // Si tenemos activo el limite lo ponemos en cada caja de cantidad.
      if (this.config_app.stock_limit) {
        return this.product.stock
      }

      // Si obsolete: true ponemos el liminte del stock a quantity
      return this.product.obsolete ? this.product.stock : ''
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    productImg () {
      if (this.product.image_url) {
        return this.product.image_url
      }
      return require('@/assets/img/no_disponible.png')
    }
  },
  methods: {
    openModal (id) {
      this.id = id
      this.quantity = 1
      this.getProduct()
      // console.log('xx---product:', product)
    },
    getProduct () {
      SrvCatalog.getProduct(this.id, 'order')
        .then(res => {
          // console.log('res-----> ', res)
          if (res.status === 200) {
            // console.log('--->Producto: ', res.data)
            this.product = res.data

            // Descripción
            if (this.product.extra_info.description !== undefined || this.product.extra_info['Descripción'] !== undefined) {
              this.description = this.product.extra_info.description ? this.product.extra_info.description : ''
              this.description = this.product.extra_info['Descripción'] ? this.product.extra_info['Descripción'] : ''
            } else {
              this.description = this.product.description
            }
            // Marcas
            if (this.product.extra_info.brand !== undefined || this.product.extra_info['Marca'] !== undefined) {
              this.brand = this.product.extra_info.brand ? this.product.extra_info.brand : '-'
              this.brand = this.product.extra_info['Marca'] ? this.product.extra_info['Marca'] : '-'
            }

            // formato
            this.aFormato = this.product.aditional_measurement_units
            if (this.aFormato && this.aFormato.length > 0) {
              const unit = this.product.measurement_unit
              const formato = {'id': '', 'name': unit.name, 'quantity': unit.quantity}
              this.aFormato = [formato, ...this.aFormato]
              this.changeFormato(this.aFormato[0])
            }
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    checkQuanty () {
      // obsolete_limit: true y product obsolete: true no dejamos que pase el limite del stock.
      if (this.product.obsolete && this.config_app.obsolete_limit) {
        this.quantity = Math.max(Math.min(Math.round(this.quantity), this.limitQuantity), 0)
      }
    },
    toAddCart () {
      // console.log(`Add Cart --- id product: ${this.product.id}, quantity: ${this.quantity}`)
      if (this.quantity > 0) {
        let item = {idProduct: this.product.id, quantity: this.quantity, product: this.product}
        if (this.config_app.formato && this.aFormato.length > 0) {
          item.aditional_measurement_unit = this.formatoItem.id
        }
        this.$store.dispatch('addCart', item)
      }
    },
    toGoProduct (id) {
      this.$emit('toGoProduct', id)
    },
    changeFormato (item) {
      // console.log(`id: ${item.id}, name: ${item.name}, quantity: ${item.quantity}`)
      this.formatoItem = item
    },
    submit () {
      this.$refs.btnSubmit.click()
    },
    callCotiza () {
      const btn = document.getElementById(`cotiza-btn-${this.product.id}`)
      btn.click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #E1EDFE;
    padding: 20px 30px 14px;
    border-bottom: 1px solid #ABC7F1;

    .row-flex{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h2{
      font-size: 18px;
      color: #1C2C40;
      margin: 0;
    }

    .close{
      float: none;
      opacity: 1;
    }
  }

  .modal-body{
    padding: 28px;

    .box-ft{
      border-top: 1px solid #ABC7F1;
      padding-top: 20px;
      margin: 26px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p{
        margin: 0;
      }
    }

    .holder-img {
      position: relative;
      border: 1px solid #89ACE3;
      padding: 10px;
      width: 100%;
      height: 392px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > small{
        position: absolute;
        z-index: 9;
        top:0;
        left:0;
        display: block;
        padding: 4px;
        color: $white;
        text-transform: uppercase;
        font-size:10px;
      }

      &.type-new{
        border-color: rgba(#8D22A7, .77);

        & > small{
          background-color: #4467C8;
        }
      }

      &.type-eco{
        border-color: #9CE16D;

        & > small{
          background-color: #9CE16D;
        }
      }

      &.type-outlet{
        border-color: #f0bd4e;

        & > small{
          background-color: #f0bd4e;
        }
      }

      img{
        max-width: 100%;
        height: auto;
        max-height: 380px;
      }
    }

    .descripction-cn{
      font-size: 14px;
      line-height: 24px;
      margin-bottom:40px;
    }

    .box-info-popup{
      display: flex;
      // align-items: center;
      justify-content: space-between;
    }

    .info-cn{
        margin-bottom:18px;
        padding: 0;
        min-height: 72px;

        li{
          list-style: none;
          font-size: 14px;
          line-height: 24px;
          strong{
            font-weight: $regular;
            margin-left: 8px;
          }
        }
    }

    .info-addCart{
      width: 200px;
      background-color: #EBF3FE;
      padding: 16px;
      .pvp{
        font-size: 14px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        strong{
          font-size: 16px;
          font-weight: $medium;
        }
      }

      .und{
        font-size:16px;
        color:#2E811A;
        margin-bottom: 16px;

        &.noStock{
          color:#ff5466;
        }

        &.normalStock{
          color: $color-text;
        }
      }

      .formato{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Select-bk.dropdown{
            width: 100px;

            .dropdown-toggle{
              @include roundAll(4);
              border-color: #e6e8e9;
              padding-right: 30px;
              height: 32px;
              line-height: 32px;
              i{
                line-height: 32px;
              }
            }
        }
      }

      .cant{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        span{
          display: inline-block;
        }

        input{
          width:60px;
          border:none;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border: 1px solid #E6E8E9;
          font-size: 13px;
          @include roundAll(4);
          overflow: hidden;
          padding-left: 10px;
        }
      }

      .pvpTotal{
        text-align: right;

        span{
          display:inline-block;
          font-size: 20px;
          font-weight: $medium;
          display: inline-block;
          color: #303030;

          &.special{
            color: $color-precio-special;
          }

          &.obsolete{
            color: #f0bd4e;
          }
        }
      }
    }

    .doc-cn{
      a{
        display: inline-block;
        width: 160px;
        line-height: 43px;
        height: 43px;
        text-align: center;
        border: 1px solid $blue-light;

        img{
          margin-right: 8px;
        }

        &:hover, &:focus{
          border-color: darken($blue-light, 10%);
          text-decoration: none;
        }

        &:not(:last-child){
          margin-right: 19px;
        }
      }
    }

    p.submit{
      width: 200px;
      button{
        width: 100%;
      }
    }
  }

  .cotiza-single{
    width: 250px;
    background-color: #EBF3FE;
    padding: 16px;

    p{
      font-size: 14px;
      line-height: 18px;
    }

    .btn{
      background-color: #fff;
      color: $orange;
      text-transform: uppercase;
      font-size: 16px;
      border: 1px solid #E2E2E2;
      @include roundAll(6);
      width: 100%;
      height: 40px;
      line-height: 40px;
      margin-top: 10px;
      padding: 0;
    }
  }
</style>
