<template>
<div>
  <div class="tw-w-[1200px] tw-m-auto tw-flex tw-items-center tw-mt-[15px]">
    <div class="tw-w-1/2">
      <div class="tw-w-1/2 tw-text-[18px] tw-flex tw-items-center tw-ml-[12px]">
        {{ count }} Encuestas
      </div>
    </div>
    <div class="tw-w-1/2 tw-flex tw-justify-end tw-items-center">
        <label class=" !tw-mb-0 !tw-font-normal"><translate> Ver:</translate></label>
        <div class="dropdown Select-bk tw-border tw-border-[#CECECE] tw-h-[34px] !tw-mb-0 !tw-w-[163px]">
          <button style="width:100%" class="form-controlbtn btn-default dropdown-toggle !tw-h-[32px] !tw-leading-[32px]" :class="{'disabled': filterPolls.length === 1}" type="button" id="ddCat1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"> {{filterPollsActive.value}}
            <i class="fa fa-angle-down !tw-leading-[32px]" aria-hidden="true"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="ddCat1">
            <li v-for="item in filterPolls" :class="{'disabled': filterPollsActive.key == item.key}">
              <span :value="item.key" @click="changeFilterPolls(item)"> {{item.value}}</span>
            </li>
              
          </ul>
        </div>
      <date-picker class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="date" 
    :first-day-of-week="calendar.firstDayOfWeek" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" 
    not-before="2016-01-01" @input="changeCalendar" confirm />
    </div>    
  </div>
  <div class="tw-w-[1200px] tw-m-auto tw-mt-[15px]">
    
    <div class="rowComment tw-text-[#43425D]">
      <div class="tw-w-[8%] tw-px-[15px] tw-border-r tw-border-[#F0F0F0] tw-flex tw-self-stretch tw-items-end tw-py-[10px]">
        <div class="tw-flex tw-items-center">
          NPS
          <button @click="setOrder(orderActive.indexOf('-nps') == 0 ? 'nps' : '-nps')" class="tw-ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" class="tw-rotate-180" :class="{'!tw-rotate-0': orderActive.indexOf('-nps') == 0}">
              <path id="Polígono_6" data-name="Polígono 6" d="M5,0l5,9H0Z" transform="translate(10 9) rotate(180)" :fill="orderActive.indexOf('nps') > -1 ? '#00ADFC' :'#d5d5d5'"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="tw-w-[14%] tw-px-[15px] tw-border-r tw-border-[#F0F0F0] tw-flex tw-self-stretch tw-items-end tw-py-[10px]">CLIENTE/CENTRO</div>
      <div class="tw-w-[71%] tw-px-[15px] tw-border-r tw-border-[#F0F0F0] tw-flex tw-self-stretch tw-items-end tw-py-[10px]">
        <div class="tw-flex tw-w-full tw-min-h-[46px]">
            <div class="tw-w-1/2 tw-flex tw-items-end tw-border-r tw-border-[#F0F0F0]">
              <p class="tw-px-[14px]">PREGUNTAS</p>
            </div> 
            <div class="tw-w-1/2 tw-flex tw-justify-center">
              <div class="tw-w-1/5 tw-flex tw-flex-col tw-justify-center tw-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.186" height="19.186" viewBox="0 0 19.186 19.186">
                  <g id="Grupo_1" data-name="Grupo 1" transform="translate(0.5 0.5)">
                    <circle id="Elipse_1" data-name="Elipse 1" cx="9.093" cy="9.093" r="9.093" transform="translate(0 0)" fill="#fff" stroke="#006838" stroke-miterlimit="10" stroke-width="1"/>
                    <circle id="Elipse_2" data-name="Elipse 2" cx="1.292" cy="1.292" r="1.292" transform="translate(3.724 6.222) rotate(-45)" fill="#006838"/>
                    <circle id="Elipse_3" data-name="Elipse 3" cx="1.292" cy="1.292" r="1.292" transform="translate(10.903 6.222) rotate(-45)" fill="#006838"/>
                    <path id="Rectángulo_1" data-name="Rectángulo 1" d="M.3,0H6.875a.3.3,0,0,1,.3.3V.526A3.589,3.589,0,0,1,3.589,4.116h0A3.589,3.589,0,0,1,0,.526V.3A.3.3,0,0,1,.3,0Z" transform="translate(5.552 11.199)" fill="#006838"/>
                  </g>
                </svg>
                <span class="tw-text-[12px] tw-text-[#363636] tw-mt-[6px]">Muy buena</span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-flex-col tw-justify-center tw-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.186" height="19.186" viewBox="0 0 19.186 19.186">
                  <g id="Grupo_99" data-name="Grupo 99" transform="translate(-7.202 -7.202)">
                    <g id="Grupo_3" data-name="Grupo 3" transform="translate(7.702 7.702)">
                      <circle id="Elipse_7" data-name="Elipse 7" cx="9.093" cy="9.093" r="9.093" transform="translate(0 0)" fill="#fff" stroke="#8dc63f" stroke-miterlimit="10" stroke-width="1"/>
                      <circle id="Elipse_8" data-name="Elipse 8" cx="1.292" cy="1.292" r="1.292" transform="translate(3.676 6.222) rotate(-45)" fill="#8dc63f"/>
                      <circle id="Elipse_9" data-name="Elipse 9" cx="1.292" cy="1.292" r="1.292" transform="translate(10.855 6.222) rotate(-45)" fill="#8dc63f"/>
                      <path id="Trazado_1" data-name="Trazado 1" d="M1346.723,1610.188a.719.719,0,0,1,.13,1.077,6.25,6.25,0,0,1-9.361,0,.719.719,0,0,1,.13-1.077l.012-.008a.721.721,0,0,1,.938.113,4.87,4.87,0,0,0,7.2,0,.721.721,0,0,1,.938-.113Z" transform="translate(-1332.791 -1598.609)" fill="#8dc63f"/>
                    </g>
                  </g>
                </svg>
                <span class="tw-text-[12px] tw-text-[#363636] tw-mt-[6px]">Buena</span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-flex-col tw-justify-center tw-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.186" height="19.186" viewBox="0 0 19.186 19.186">
                  <g id="Grupo_98" data-name="Grupo 98" transform="translate(-7.202 -7.202)">
                    <g id="Grupo_5" data-name="Grupo 5" transform="translate(7.702 7.702)">
                      <circle id="Elipse_13" data-name="Elipse 13" cx="9.093" cy="9.093" r="9.093" transform="translate(0 0)" fill="#fff" stroke="#fbb040" stroke-miterlimit="10" stroke-width="1"/>
                      <circle id="Elipse_14" data-name="Elipse 14" cx="1.292" cy="1.292" r="1.292" transform="translate(3.676 6.222) rotate(-45)" fill="#fbb040"/>
                      <circle id="Elipse_15" data-name="Elipse 15" cx="1.292" cy="1.292" r="1.292" transform="translate(11.128 7.182) rotate(-76.717)" fill="#fbb040"/>
                      <rect id="Rectángulo_3" data-name="Rectángulo 3" width="7.179" height="1.292" rx="0.646" transform="translate(5.504 12.443)" fill="#fbb040"/>
                    </g>
                  </g>
                </svg>
                <span class="tw-text-[12px] tw-text-[#363636] tw-mt-[6px]">Satisfactoria</span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-flex-col tw-justify-center tw-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.186" height="19.186" viewBox="0 0 19.186 19.186">
                  <g id="Grupo_97" data-name="Grupo 97" transform="translate(-7.202 -7.202)">
                    <g id="Grupo_4" data-name="Grupo 4" transform="translate(7.702 7.702)">
                      <circle id="Elipse_10" data-name="Elipse 10" cx="9.093" cy="9.093" r="9.093" transform="translate(0 0)" fill="#fff" stroke="#e05166" stroke-miterlimit="10" stroke-width="1"/>
                      <circle id="Elipse_11" data-name="Elipse 11" cx="1.292" cy="1.292" r="1.292" transform="matrix(0.23, -0.973, 0.973, 0.23, 3.949, 7.182)" fill="#e05166"/>
                      <circle id="Elipse_12" data-name="Elipse 12" cx="1.292" cy="1.292" r="1.292" transform="translate(11.128 7.182) rotate(-76.717)" fill="#e05166"/>
                      <path id="Trazado_2" data-name="Trazado 2" d="M3250.308,1582.284a.719.719,0,0,1-.13-1.077,6.25,6.25,0,0,1,9.361,0,.719.719,0,0,1-.129,1.077l-.012.008a.721.721,0,0,1-.938-.113,4.87,4.87,0,0,0-7.2,0,.721.721,0,0,1-.938.113Z" transform="translate(-3245.574 -1568.314)" fill="#e05166"/>
                    </g>
                  </g>
                </svg>
                <span class="tw-text-[12px] tw-text-[#363636] tw-mt-[6px]">Mala</span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-flex-col tw-justify-center tw-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.186" height="19.186" viewBox="0 0 19.186 19.186">
                  <g id="Grupo_101" data-name="Grupo 101" transform="translate(-7.202 -7.202)">
                    <g id="Grupo_2" data-name="Grupo 2" transform="translate(7.702 7.702)">
                      <circle id="Elipse_4" data-name="Elipse 4" cx="9.093" cy="9.093" r="9.093" transform="translate(0 0)" fill="#fff" stroke="#be1e2d" stroke-miterlimit="10" stroke-width="1"/>
                      <circle id="Elipse_5" data-name="Elipse 5" cx="1.292" cy="1.292" r="1.292" transform="matrix(0.23, -0.973, 0.973, 0.23, 3.949, 7.182)" fill="#be1e2d"/>
                      <circle id="Elipse_6" data-name="Elipse 6" cx="1.292" cy="1.292" r="1.292" transform="translate(11.2 7.29) rotate(-80.783)" fill="#be1e2d"/>
                      <path id="Rectángulo_2" data-name="Rectángulo 2" d="M.3,0H6.875a.3.3,0,0,1,.3.3V.526A3.589,3.589,0,0,1,3.589,4.116h0A3.589,3.589,0,0,1,0,.526V.3A.3.3,0,0,1,.3,0Z" transform="translate(12.683 13.736) rotate(180)" fill="#be1e2d"/>
                    </g>
                  </g>
                </svg>
                <span class="tw-text-[12px] tw-text-[#363636] tw-mt-[6px]">Muy mala</span>
              </div> 
            </div>          
          </div>
      </div>
      <div class="tw-w-[7%] tw-px-[15px] tw-flex tw-self-stretch tw-items-end tw-py-[10px]">
        <div class="tw-flex tw-items-center">
          FECHA
          <button @click="setOrder(orderActive.indexOf('-created') == 0 ? 'created' : '-created')" class="tw-ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" class="tw-block !tw-h-[14px] tw-rotate-180" :class="{'!tw-rotate-0': orderActive.indexOf('-created') == 0}">
              <path id="Polígono_6" data-name="Polígono 6" d="M5,0l5,9H0Z" transform="translate(10 9) rotate(180)" :fill="orderActive.indexOf('created') > -1 ? '#00ADFC' :'#d5d5d5'"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in aComments" :key="index">
      <div class="rowComment !tw-items-start" :class="{'!tw-border-b-0': item.comment}">
        <div class="tw-w-[8%] tw-px-[15px] tw-border-r tw-border-[#F0F0F0] tw-flex tw-self-stretch tw-items-start tw-pt-[23px]">
          <div class="tw-rounded-[5px] tw-w-[15px] tw-h-[15px]" :class="bgNps(item.answers)"></div>
          <div class="tw-ml-[10px]">{{ item.answers.filter(e => e.is_nps).length > 0 ? item.answers.filter(e => e.is_nps)[0].value : 0}}</div>
        </div>
        <div class="tw-w-[14%] tw-px-[15px] tw-border-r tw-border-[#F0F0F0] tw-flex tw-self-stretch  tw-pt-[23px]">
          <p>{{ item.client }}</p>
          <p>{{ item.center }}</p>
        </div>
        <div class="tw-w-[71%] tw-border-r tw-border-[#F0F0F0]">
          <div class="tw-flex tw-min-h-[46px]" v-for="(itemAnswer, indexAnswer) in item.answers.filter(e => !e.is_nps)" :class="{'tw-bg-[#F3F9FF]': indexAnswer % 2 == 0}">
            <div class="tw-w-1/2 tw-flex tw-items-center tw-border-r tw-border-[#F0F0F0]">
              <p class="tw-px-[14px]">{{ itemAnswer.question_text }}</p>
            </div> 
            <div class="tw-w-1/2 tw-flex tw-justify-center">
              <div class="tw-w-1/5 tw-flex tw-justify-center tw-items-center">
                <span class="boxChecked" :class="{'tw-bg-[#006838]': itemAnswer.value === 5}"></span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-justify-center tw-items-center">
                <span class="boxChecked" :class="{'tw-bg-[#8DC63F]': itemAnswer.value === 4}"></span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-justify-center tw-items-center">
                <span class="boxChecked" :class="{'tw-bg-[#FBB040]': itemAnswer.value === 3}"></span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-justify-center tw-items-center">
                <span class="boxChecked" :class="{'tw-bg-[#E05166]': itemAnswer.value === 2}"></span>
              </div> 
              <div class="tw-w-1/5 tw-flex tw-justify-center tw-items-center">
                <span class="boxChecked" :class="{'tw-bg-[#BE1E2D]': itemAnswer.value === 1}"></span>
              </div> 
            </div>          
          </div>
        </div>
        <div class="tw-w-[7%] tw-px-[15px] tw-flex tw-self-stretch tw-items-start tw-text-[#9D9FA1] tw-pt-[23px]">
          {{ item.created | moment("DD/MM/YYYY") }}
        </div>
      </div>
      <div v-if="item.comment" class="rowComment !tw-border-y-0 tw-text-[#43425D]">
        <div class="tw-w-[8%] tw-px-[15px] tw-border-r tw-border-[#F0F0F0] tw-flex tw-self-stretch tw-items-center"></div>
        <div class="tw-w-[14%] tw-px-[15px] tw-border-r tw-border-t tw-border-[#F0F0F0] tw-flex tw-self-stretch tw-items-center">Comentario:</div>
        <div class="tw-w-[71%] tw-px-[15px] tw-border-r tw-border-t tw-border-[#F0F0F0] tw-flex tw-self-stretch tw-items-center tw-text-[#368BB4] tw-text-[13px]">{{ item.comment }}</div>
        <div class="tw-w-[7%] tw-px-[15px] tw-flex tw-self-stretch tw-items-center"></div>
      </div>
    </div>
    <div v-if="aComments.length === 0" class="tw-text-center tw-p-4">No se han encontrado encuestas.</div>
    <div class="tw-flex tw-m-auto tw-justify-center tw-items-center tw-pt-8 tw-border-t tw-border-[#F0F0F0]">
      
      <span class="tw-text-[12px] tw-mr-[11px]">{{ currentPage }} de {{ totalPages }}</span>
      <ek-paginate
      class="!tw-m-0 tw-flex"
      :page-count="totalPages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="totalPages"
          ></ek-paginate>
  </div>
  </div>
  
</div>
</template>

<script>
import EkPaginate from 'vuejs-paginate'
import appConfig from '@/config-app.js'
import DatePicker from 'vue2-datepicker'
import SrvSurveys from '@/services/surveys.js'

export default {
  name: 'SurveysComments',
  components: { EkPaginate, DatePicker },
  data () {
    return {  
      orderActive: '-created',    
      paginationTxt: {
        'prev': this.$gettext('<'),
        'next': this.$gettext('>')
      },
      aComments: [],
      totalPages: 2,
      count: null,
      currentPage: 1,
      filterPollsActive: {key: 'all', value: 'Todas'},
      filterPolls: [{key: 'all', value: 'Todas'},{key: 'without', value: 'Sin comentarios'}, {key: 'with', value: 'Con comentarios'}],
      'calendar': {
        'before': this.$moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().add({'years': -1}).format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().add({'years': -1}).format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY', // 'MMM YYYY'
        'firstDayOfWeek': 1
      }
    }
  },
  methods: {
    setOrder(order) {
      this.currentPage = 1
      this.orderActive = order
      this.getAnswers()
    },
    bgNps(answers){
      if (answers.filter(e => e.is_nps).length > 0){
        if (answers.filter(e => e.is_nps)[0].value < 7) {
          return 'tw-bg-[#BE1E2D]'
        }
        if (answers.filter(e => e.is_nps)[0].value >= 7 && answers.filter(e => e.is_nps)[0].value < 9) {
          return 'tw-bg-[#F5A623]'
        }
        if (answers.filter(e => e.is_nps)[0].value > 8 && answers.filter(e => e.is_nps)[0].value <= 10) {
          return 'tw-bg-[#89C445]'
        }
      } else {
        return 'tw-bg-[#BE1E2D]'
      }
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after} - ${this.calendar.before}`)
        this.currentPage = 1
        this.getAnswers()
      }
    },
    async getAnswers(){
      const res = await SrvSurveys.getAnswers(1,this.filterPollsActive.key,this.calendar.before, this.calendar.after, this.orderActive, this.currentPage)
      if (res.status === 200) {
        this.aComments = res.data.results
        this.count = res.data.count
        this.totalPages = res.data.total_pages
        //this.currentPage = page
      }
    },
    changeFilterPolls(item){
      this.currentPage = 1
      this.filterPollsActive = item
      this.getAnswers()
    },
    pagChange (pageNum) {
      console.log(pageNum)
      this.currentPage = pageNum
      this.getAnswers()
    },
  },
  created() {
    this.getAnswers()
  },
}
</script>

<style scoped lang="scss">
.rowComment{
  @apply tw-border-y tw-border-y-[#DFE3E6] tw-min-h-[44px] tw-flex tw-items-center tw-text-[12px];
}
.boxChecked{
  @apply tw-w-[16px] tw-h-[16px] tw-rounded-[16px] tw-border tw-border-[#707070] tw-block;
}
.pagination{
  li{
    margin:0 !important;
    a{
      border-top:0 !important;
      border-bottom: 0 !important;
      min-width: 44px !important;
      text-align: center;
    }
  }
}
</style>