import Stores from "@/store/index";
import * as types from "@/store/mutation-types";
import appConfig from "@/config-app.js";

import axios from "axios";
axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  getData(before, after) {
    const oClient = Stores.state.client.client
    const oCenter = Stores.state.center.center

    const idClient = (oClient) ? oClient.id : ''
    const idCenter = (oCenter) ? oCenter.id : ''

    const query = `/info_co2e/?client=${idClient}&center=${idCenter}&start_date=${before}&end_date=${after}`;

    axios.defaults.headers.common["Authorization"] =
      "JWT " + window.localStorage.getItem("_token");

    if (Stores.getters.getCancelAxios) {
      Stores.getters.getCancelAxios.cancel(
        "Start new search, stop active search"
      );
    }

    Stores.commit(types.CANCEL_AXIOS, axios.CancelToken.source());

    return axios.get(query, {
      cancelToken: Stores.getters.getCancelAxios.token,
    });
  },
  getDataFrecuencyOrders(before, after, ordering) {
    const oClient = Stores.state.client.client
    const idClient = (oClient) ? oClient.id : ''

    const query = `/frequency_co2e/${idClient}/?start_date=${before}&end_date=${after}&ordering=${ordering}`;

    axios.defaults.headers.common["Authorization"] =
      "JWT " + window.localStorage.getItem("_token");

    if (Stores.getters.getCancelAxios) {
      Stores.getters.getCancelAxios.cancel(
        "Start new search, stop active search"
      );
    }

    Stores.commit(types.CANCEL_AXIOS, axios.CancelToken.source());

    return axios.get(query, {
      cancelToken: Stores.getters.getCancelAxios.token,
    });
  },
  getCertifiedPDF(before, after, co2e) {
    const oClient = Stores.state.client.client
    const idClient = (oClient) ? oClient.id : ''
    const oCenter = Stores.state.center.center
    const idCenter = (oCenter) ? oCenter.id : ''
    let query = `/client_co2e/${idClient}/certificate/?start_date=${before}&end_date=${after}&co2e=${co2e}`;
    if (oCenter) {
      query = `/center_co2e/${idCenter}/certificate/?start_date=${before}&end_date=${after}&co2e=${co2e}`;
    }

    axios.defaults.headers.common["Authorization"] =
      "JWT " + window.localStorage.getItem("_token");

    if (Stores.getters.getCancelAxios) {
      Stores.getters.getCancelAxios.cancel(
        "Start new search, stop active search"
      );
    }

    Stores.commit(types.CANCEL_AXIOS, axios.CancelToken.source());

    return axios.get(query, {
      'responseType': 'blob',
      cancelToken: Stores.getters.getCancelAxios.token,
    });
  },
};
