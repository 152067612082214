import Vue from 'vue'

const formatNumber = {}

formatNumber.install = function (vue) {
  Vue.filter('formatNumber', (val) => {
    return Number(val).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  })
}

export default formatNumber
