<template>
  <div class="tw-animate-spin tw-rounded-full tw-border-solid !tw-border-t-transparent tw-w-16 tw-h-16 tw-border-4 tw-border-[#0085FF] tw-top-[45%] tw-left-[50%]" :class="{'tw-fixed': fixed, 'tw-top-[calc(50vh-45px)]': fixed, 'tw-left-[calc(50vw-45px)]': fixed}"></div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    fixed: {
      type: Boolean,
      default: true
    }
  }
}
</script>