<template lang="pug">
  #clientList
      .box-2
        .container
          .row
            .col-sm-12
              bk-search(@toSearch="toSearch")

      bk-grid(
        :clients="aClient"
        :message="message"
        :pages="totalPages"
        :count="clientsNum"
        :currentPage="currentPage"
        @toPaginate="pagChange")
</template>
<script>
import BkSearch from '@/components/client/Search.vue'
import BkGrid from '@/components/client/Listado.vue'

// importamos servicios
import SrvClients from '@/services/clients'

export default {
  name: 'ClientList',
  components: { BkSearch, BkGrid },
  data () {
    return {
      message: null,
      aClient: [],
      clientsNum: null,
      totalPages: 0,
      init: false, // Para comprobar si es la primera vez que llamamos a la API
      currentPage: 0, // Para saber porque página vamos en la paginación
      query: '' // Donde se guarla la palabra a buscar.
    }
  },
  computed: {
    country () {
      return this.$store.getters.userInfo.country
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // llamamos a la API una vez se crea la instancia.
    this.resetSearch()

    // eliminamos el lead seleccionado si existe;
    this.$store.dispatch('removeLead')
    this.$store.commit('REMOVE_LEAD')
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.search(this.query, pageNum)
    },
    toSearch (query) {
      this.query = query
      this.search(this.query, 1)
    },
    search (query, page) {
      // console.log(`--->Client List toSearch: ${query}`)
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvClients.getClients(query, page)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Clientes: ', res.data.results)
            // console.log('--->Clientes: ', res.data.results[0])
            // Añadimoslos clientes
            this.aClient = res.data.results
            this.clientsNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page

            // Si es el resultado inicial y solo tiene un cliente
            if (!this.init && this.clientsNum === 1) {
              // Si solo hay uno, emulkamos el click sobre el cliente para que seleccione el cliente y el centro si solo tiene uno.
              setTimeout(() => { window.$(`.ClientListItem .js-query-click`).click() }, 10)
            } else {
              // Enviamos a Store Client como no seleccionado
              // this.$store.dispatch('removeClient')
              // Lo pasamos a su State
              // this.$store.commit('REMOVE_CLIENT')
            }
            this.init = true
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, error => {
          // console.log('----->' + error)
          let code = error.toString()
          // console.log(code + '-------------' + code.indexOf('403'))
          if (code.indexOf('403')) {
            // console.log('redirect')
            // this.$router.push('/')
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        })
    },
    resetSearch () {
      // console.log('resetSearch')
      // Que solo realice la petición cuando este en la pág "Listado de clientes." o en el "catálogo"
      if (this.$route.name === 'clientList' || this.$route.name === 'catalogoList') {
        this.query = ''
        this.search(this.query, 1)
      }
    }
  },
  watch: {
    // es llamada cuando el metodo $router cambia.
    $route (to, from) {
      // console.log(`change router: ${to.name}, ${from.name}`)
      this.resetSearch()
    },
    country () {
      // actualizamos el valor de los pedidos pendientes.
      this.resetSearch()
    }
  }
  /* beforeRouteEnter: (to, from, next) => {
    // console.info('antes de entrar llamamos a la API para traer datos')
    next((vm) => {
      // llamamos a la api para traer los clientes
      SrvClients.getClients('')
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Clientes: ', res.data.results[0])
            // Añadimoslos clientes
            vm.aClient = res.data.results
            vm.clientsNum = res.data.count
            vm.totalPages = res.data.total_pages
          } else {
            vm.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // console.log('----->' + error)
          vm.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    })
  } */
}
</script>
<style lang="scss" scoped>

  .box-2{
    position:relative;
    z-index:1;
    background-color: #89ACE3;
    padding-top:14px;
    padding-bottom:14px;
  }
</style>
