<template lang="pug">
  #ClientListGrid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}

    .holder-cn(v-if="count")
      .container
        .row.grid-head
          .col-sm-4
            p.name #[translate Nombre del cliente / Lead]
          .col-sm-3
            p.name #[translate Usuario]
          .col-sm-1.nun-oferta
            p.text-right(style="visibility:hidden") #[translate Nº de Oferta]
          .col-sm-1
            p.text-center #[translate Fecha]
          .col-sm-2
            p.text-right #[translate Total]
          .col-sm-1
            p.text-center

      .grid-cn(v-if="count")
        .container
          bk-item( v-for="(order, index) in orders" :key="order.id" :order="order" :index="index" :typeItem="typeGrid")

      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")

    .container(v-if="count == 0 && typeGrid === 'validados'")
      .row
        .col-sm-12
          p(class="alert alert-info" role="alert") #[translate No se han encontrado Ofertas].
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/validar-ofertas/Item.vue'

export default {
  name: 'ValidarPedidosListGrid',
  props: ['orders', 'typeGrid', 'message', 'pages', 'count', 'currentPage'],
  data () {
    return {
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      }
    }
  },
  created () {
    // console.log(`--- type: ${this.typeGrid}, pages: ${this.pages}, count: ${this.count}, currentPage: ${this.currentPage}`)
  },
  components: { BkItem, EkPaginate },
  computed: {
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    }
  }
}
</script>
<style lang="scss" scoped>

  .grid-head{
    margin-top: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid #1C2C40;
    display: flex;
    align-items: center;
    & > *{
      padding-bottom: 6px;
      font-size: 14px;

      &:first-child{
        font-weight: $medium;
      }
    }

    .nun-oferta{
      padding-left: 0;
    }

    p{
      margin: 0;
    }
  }

  .num-cn{
    padding-left: 0;
    padding-right: 0;
  }

  .grid-cn{
    margin-bottom: 24px;

    & > *{
      padding:0;
    }

    .ClientListItem{
      &:nth-child(even){
        background-color: rgba(#F1F4F7, .38);
      }

      &.type-validado {
        &:last-child{
            border-bottom: 1px solid #1C2C40;
        }
      }
    }
  }

  .alert{
    margin-top:40px;
  }
</style>
