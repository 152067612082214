// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getOrders (querySearch, page, before, after, state = null) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      const oClient = Stores.getters.client
      const oCenter = Stores.getters.center

      const idClient = (oClient) ? oClient.id : ''
      const idCenter = (oCenter) ? oCenter.id : ''

      let countryTmp = Stores.getters.userInfo.country
      let country = (countryTmp && countryTmp.id !== 'eu') ? countryTmp.id : ''

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      let url = `/orders/?country=${country}&client=${idClient}&center=${idCenter}&search=${querySearch}&page=${page}&before=${before}&after=${after}`
      if (state) { url += `&state=${state}` }
      axios.get(url)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getOrder (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API
      // console.log('Serv getOrder, localstorage: ', window.localStorage)
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/orders/${id}/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
