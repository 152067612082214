<template lang="pug">
  .ClientListItem.container
    .row.box-1
      div(:class="{'col-sm-8': !config_app.client_locked, 'col-sm-6': config_app.client_locked}")
        p
          span.img-cn.tw-flex(data-toggle="collapse" :data-target="'#clients-'+index")
            img(:src="img.derecha" width="8")
          span.name(@click="setClient") {{client.name}}
      .col-sm-2.client-code(@click="setClient")
        p.text-center
          span {{client[config_app.client_code]}}
      .col-sm-2(@click="setClient" v-if="config_app.client_locked")
        p.text-center(v-if="client.locked")
          img(:src="img.bloqueado" width="18")
        p.text-center(v-else) &nbsp;
      .col-sm-2(class="js-query-click" @click="setClient")
        p.count-cn
          span.text-right {{client.center_count}}

    .row.box-2(:id="'clients-'+index" class="collapse")
      div.col-l(:class="{'col-sm-8': !config_app.client_locked, 'col-sm-6': config_app.client_locked}")
        p #[translate Dirección]: #[strong {{checkEmpty(client.address)}}]
        p #[translate Localidad]: #[strong {{checkEmpty(client.locality)}}]
        p #[translate Provincia]: #[strong {{checkEmpty(client.province)}}]
        p #[translate Código Postal]: #[strong {{checkEmpty(client.postal_code)}}]
      .col-sm-4
        p #[translate Persona de contacto]:
        p #[translate Teléfono]: #[strong {{checkEmpty(client.phone)}}]
        p #[translate Correo electrónico]: #[strong {{checkEmpty(client.email)}}]
        p #[translate Comercial]: #[strong {{checkEmpty(client.contact)}}]
</template>
<script>
import * as types from '@/store/mutation-types'
// importamos servicios Center
import SrvCenter from '@/services/center'

export default {
  name: 'ClientListItem',
  props: ['client', 'index'],
  data () {
    return {
      img: {
        bloqueado: require('@/assets/img/icos/bloqueado.svg'),
        derecha: require('@/assets/img/icos/derecha.svg')
      }
    }
  },
  computed: {
    catalogueOptions () {
      return this.$store.getters.catalogueOptions
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    setClient () {
      // console.log(`num clientes: ${this.client.center_count} , cliente seleccionado:`, this.client)

      // Enviamos a Store Client los datos del cliente seleccionado
      this.$store.dispatch('setClient', this.client)
      // Lo pasamos a su State
      this.$store.commit('SET_CLIENT')
      // Comprobamos si tiene un centro para seleccionarlo automáticamente.
      this.getCenters()
      // Le enviamos a la home.
      this.$router.push({name: this.config_app.url_home})
    },
    getCenters () {
      // console.log(`--getCenters--> isClient: ${this.client.center_count}`)
      if (this.client.center_count === 1) {
        SrvCenter.getCenters('', 1)
          .then(res => {
            // comprobamos el número de centros que tiene el cliente y si solo tiene uno se lo asignamos.
            // console.log(`nº center ${res.data.count}`)
            if (res.status === 200 && res.data.count === 1) {
              const center = res.data.results[0]
              // como no vienen todos los datos hacemos una petición para traernos todos sus datos.
              this.getCenter(center.id)
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // console.log('----->' + error)
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      } else {
        // Si no hay cliente, quitamos el centro.
        this.$store.dispatch('removeCenter')
        // Lo pasamos a su State
        this.$store.commit('REMOVE_CENTER')
      }
    },
    getCenter (centerId) {
      SrvCenter.getCenter(centerId)
        .then(res => {
          // console.log(`center seleccionado: `, res)
          if (res.status === 200) {
            // Enviamos a Store Center los datos del centro que recibimos con el has_facturable
            this.$store.dispatch('setCenter', res.data)
            // Lo pasamos a su State
            this.$store.commit('SET_CENTER')
            // enviamos a la store que la ordenación sea por consumo.
            this.catalogueOptions.ordering = 'consumption_desc'
            this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)
          }
        }, () => {
          // console.log('----->' + error)
          // Enviamos a Store Center los datos del centro seleccionado
          this.$store.dispatch('setCenter', this.center)
          // Lo pasamos a su State
          this.$store.commit('SET_CENTER')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .ClientListItem{
    color:#494f58;
  }
  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;
    }

    .client-code{
      span{
        display: inline-block;
        min-width: 102px;
        text-align: right;
      }
    }
  }
  .name{
    text-transform: uppercase;
    @include ellipsis();
    display: block;
    margin-left:34px;
    @include transition();
  }

  .img-cn{
    position: absolute;
    top:0;
    bottom: 0;
    left: 15px;
    width: 32px;
    height: 22px;
    margin: auto;
    padding-right: 10px;

    img{
      @include transition();
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .count-cn{
    span{
      display:block;
      margin:0 auto;
      max-width: 90px;
    }
  }

  [aria-expanded="true"]{
      img{
        -moz-transform: rotate(90deg) translate(0px);
        -o-transform: rotate(90deg) translate(0px);
        -ms-transform: rotate(90deg) translate(0px);
        -webkit-transform: rotate(90deg) translate(0px);
        transform: rotate(90deg) translate(0px);
      }
  }

  .box-2{
    padding: 16px 0 30px;
    background-color: #fff;
    border-bottom: 1px solid #F5F6FA;
    color: #4D4F5C;
    font-weight: $light;

    .col-l{
        & > * {
          margin-left:34px;
        }
    }

    strong{
      font-weight: $regular;
    }
  }

</style>
