<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog
      .modal-content
        .modal-header.tw-text-center.tw-flex.tw-justify-center
          button(@click="next()" type="button" class="close")
            img(:src="img.close" width="25")
          span.hidden(@click="next()" type="button" class="close" data-dismiss="modal" ref="close")
            img(:src="img.close" width="25")
          <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
            <g id="Grupo_2843" data-name="Grupo 2843" transform="translate(-630 -198)">
              <circle id="Elipse_609" data-name="Elipse 609" cx="29" cy="29" r="29" transform="translate(630 198)" fill="#fff"/>
              <g id="Grupo_2842" data-name="Grupo 2842" transform="translate(644 212)">
                <path id="Trazado_5625" data-name="Trazado 5625" d="M24.359,14.725V6.77a.763.763,0,0,0-.224-.541L20.13,2.224A.763.763,0,0,0,19.59,2H4.763A.763.763,0,0,0,4,2.763V26.686a.763.763,0,0,0,.763.763h8.144M9.09,12.18h10.18M9.09,7.09h5.09M9.09,17.269h3.817m8.849,3.741,1.272-1.272a1.426,1.426,0,0,1,2.018,0h0a1.426,1.426,0,0,1,0,2.017l-1.272,1.272m-2.018-2.018L17.95,24.815a1.272,1.272,0,0,0-.356.7l-.31,1.981,1.981-.309a1.272,1.272,0,0,0,.7-.356l3.8-3.807m-2.017-2.018,2.018,2.018" transform="translate(0 0)" fill="none" stroke="#89ace3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Trazado_5626" data-name="Trazado 5626" d="M16,2V6.326a.763.763,0,0,0,.763.763H21.09" transform="translate(3.269 0)" fill="none" stroke="#89ace3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </g>
          </svg>

        .modal-body
            div(class="tw-text-center tw-mb-[20px] tw-pb-[20px]")
              p(class="tw-[#4D4F5C] tw-font-bold") #[translate ¿Quieres cancelar la edición de la oferta?]
            .tw-flex.tw-items-center.tw-justify-center 
              button(@click="next()" class="tw-border tw-border-[#979797] tw-p-[9.5px] tw-mr-4") Cerrar
              button(@click="cancelEdit()" class="tw-bg-[#E24A43] tw-text-white tw-border tw-border-[#E24A43] tw-p-[9.5px]") Cancelar
    span(class="hidden") #[translate xxx#/]
</template>
<script>
export default {
  name: 'ModalImage',
  props: ['id', 'title', 'content', 'imagen', 'aProduct'],
  data () {
    return {
      img: {
        close: require('@/assets/img/icos/x_cerrar.svg'),
        modal: require('@/assets/img/modal-soste.svg')
      },
    }
  },
  computed: {
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-cancel-edit-order'
    }
  },
  created () {
  },
  methods: {
    cancelEdit () {
      this.$emit('cancelEdit')
      this.next()
    },
    next () {
      this.$refs.close.click()      
    },
  }
}
</script>
<style lang="scss" scoped>
  .modal{
    max-width:460px;
    margin: 0 auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .modal-dialog{
    max-width: 460px;
    width: max-content;
  }
  .modal-content{
    border: none;
    @include roundAll(10);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
    .catalog-GridItem{
      float: left;
    }
  }
  .modal-header{    
    padding:0;
    background-color:#E1EDFE;
    border:0;
    position:relative;
    padding-top:20px;
    padding-left: 18px;
    padding-bottom:20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .text-center{
      text-align: center;
    }
    p{
      font-size: 18px;
    }
    .close{
      margin-top: 0;
      right:20px;
      font-size: 40px;
      opacity: 1;
      position: absolute;
      top:10px;
    }
  }

  .modal-body{
    width: max-content;
    max-width: 460px;
    min-width: 460px;
    padding: 20px;
    font-size: 14px;
    font-family: "Roboto", Arial, Verdana;
    max-height: 356px;
    .product-list{
      padding: 0;
    }
    .row{
      margin-right: 0;
      margin-left: 0;
      padding: 0;
    }
    .catalog-GridItem{
      border: 1px solid #EAEDED;
      margin-bottom: 0;
      width: 270px;
      .img-cn{
        float:left;
        width: 40%;
      }
      .text-center{
        float:right;
        width: 60%;
      }
      .code-cn{
        float:right;
        width: 60%;
      }
    }
  }

  .modal-footer{
    background-color:#EAEDED;
    text-align: center;
    button{
      background-color: #FF7343;
      color:#fff;
      padding:10px 30px;
      border:none;
    }
  }

  h2{
    font-size: 18px;
    margin: 0;
  }

  .container{
    max-width: 460px;
    width: max-content;
  }
</style>
