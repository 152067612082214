
// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getCenters (querySearch, page) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      let oClient = Stores.state.client.client
      // console.log(`querySearch: ${querySearch}, page: ${page} ,Servicio getCenters: `, oClient)
      if (oClient) {
        let client = oClient.id
        let countryTmp = Stores.getters.userInfo.country
        let country = (countryTmp && countryTmp.id !== 'eu') ? countryTmp.id : ''

        // llamada a la API
        // console.log(`getCenters token: ${window.localStorage.getItem('_token')}`)
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios.get(`/centers/?client=${client}&search=${querySearch}&page=${page}&country=${country}`)
          .then(res => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            // console.log('Store Center: ', res.data.results)
            resolve(res)
          })
          .catch(error => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
          })
      } else {
        // Si no la respuesta es 0
        resolve(0)
      }
    })
  },
  getCenter (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/centers/${id}/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  updateCenter (center) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // https://laracasts.com/discuss/channels/vue/patch-method-not-allowed
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.put('/centers/', center)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Service center Update res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
