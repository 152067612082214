<template>
    <div class="tw-m-auto tw-w-full tw-max-w-[974px] tw-px-[15px] tw-lg-px-0">
      <img src="@/assets/img/poll/head.png">
      <div class="tw-text-center tw-mt-[56px]">
        <p class="tw-leading-[24px]">¿Nos ayudas a mejorar?<br/>
        Nuestra prioridad siempre son nuestros clientes por eso sería de mucha ayuda conocer tu opinión sobre nuestros servicios</p>
      </div>
      <div class="tw-m-auto tw-lg-flex tw-mt-[40px]">
        <div class="tw-w-full .tw-lg-max-w-421px tw-rounded-[10px] tw-bg-[#F5F5F5] tw-pl-[20px] tw-pr-[30px] tw-py-[13px] tw-lg-flex tw-items-center">
          <div class="tw-mr-[18px] tw-flex-[0_1_auto]">
            <label class="" for="nombreCliente">Nombre de Cliente</label>
          </div>
          <div class="tw-flex-[1]">
            <input type="text" id="nombreCliente" v-model="nameClient" class="tw-rounded-[8px] tw-w-full tw-h-[42px]" />
          </div>
        </div>
        <div class="tw-w-full tw-mt-[30px] tw-lg-mt-0 tw-lg-max-w-512px tw-rounded-[10px] tw-bg-[#F5F5F5] tw-pl-[20px] tw-pr-[30px] tw-py-[13px] tw-lg-ml-41px tw-lg-flex tw-items-center">
          <div class="tw-mr-[18px] tw-flex-[0_1_auto]">
            <label for="nombreCentro">Centro</label>
          </div>
          <div class="tw-flex-[1]">
            <input type="text" id="nombreCentro" v-model="nameCenter" class="tw-rounded-[8px] tw-w-full tw-h-[42px]" />
          </div>
        </div>
      </div>
      <div class="tw-lg-flex tw-mt-[65px] tw-mb-[10px]">
        <div class="tw-w-full tw-max-w-[450px]"></div>
        <div class="tw-w-full tw-max-w-[500px] tw-flex tw-lg-ml-24px">
          <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
            <img src="@/assets/img/poll/muybuena.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
            <span class="tw-text-14">Muy buena</span>
          </div>
          <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
            <img src="@/assets/img/poll/buena.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
            <span class="text-14">Buena</span>
          </div>
          <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
            <img src="@/assets/img/poll/satisfactoria.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
            <span class="tw-text-14">Satisfactoria</span>
          </div>
          <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
            <img src="@/assets/img/poll/mala.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
            <span class="tw-text-14">Mala</span>
          </div>
          <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
            <img src="@/assets/img/poll/muymala.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
            <span class="tw-text-14">Muy mala</span>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in questions" class="tw-lg-flex tw-mb-[22px] tw-bg-[#F5F5F5] tw-rounded-[10px] tw-py-[21px] tw-text-14">
        <div class="tw-w-full tw-max-w-[450px] tw-px-[21px] tw-lg-pr-0">
          <p>{{ item.text }}</p>
        </div>
        <div class="tw-w-full tw-max-w-[500px] tw-flex tw-mt-3 tw-lg-mt-0 tw-lg-ml-24px">
          <div class="tw-w-1/5 tw-flex tw-text-center tw-justify-center tw-items-center">
            <RadioButton  :name="'q'+index" color="#006838" :modelValue="item.res" @change="item.res = $event.target.value" value="5"></RadioButton>
          </div>
          <div class="tw-w-1/5 tw-flex tw-text-center tw-justify-center tw-items-center">
            <RadioButton  :name="'q'+index" color="#8DC63F" :modelValue="item.res" @change="item.res = $event.target.value" value="4"></RadioButton>
          </div>
          <div class="tw-w-1/5 tw-flex tw-text-center tw-justify-center tw-items-center">
            <RadioButton  :name="'q'+index" color="#FBB040" :modelValue="item.res" @change="item.res = $event.target.value" value="3"></RadioButton>
          </div>
          <div class="tw-w-1/5 tw-flex tw-text-center tw-justify-center tw-items-center">
            <RadioButton  :name="'q'+index" color="#E05166" :modelValue="item.res" @change="item.res = $event.target.value" value="2"></RadioButton>
          </div>
          <div class="tw-w-1/5 tw-flex tw-text-center tw-justify-center tw-items-center">
            <RadioButton  :name="'q'+index" color="#BE1E2D" :modelValue="item.res" @change="item.res = $event.target.value" value="1"></RadioButton>
          </div>
        </div>
      </div>
      <div class="tw-lg-flex tw-flex-col tw-mb-[22px] tw-bg-[#F5F5F5] tw-rounded-[10px] tw-py-[21px] tw-text-14 tw-justify-center tw-align-center tw-text-center">
        <p class="tw-mb-[45px]" v-if="questionNPS">{{ questionNPS.text }}</p>
        <input-range-slider :modelValue="nps"  @update:modelValue="nps = $event" :range="inputRangeData" class="tw-w-full tw-px-[15px] tw-lg-px-0 tw-m-auto tw-max-w-[630px] tw-mb-[45px]"></input-range-slider>
      </div>
      <div class="tw-lg-flex tw-mb-[22px] tw-bg-[#F5F5F5] tw-rounded-[10px] tw-py-[21px] tw-px-[32px] tw-text-14 tw-justify-center tw-align-center">
        <label for="comentario" class="tw-flex-[0_1_auto] tw-mr-[26px]">¿Desea hacer algún comentario?</label>
        <textarea id="comentario" v-model="comment" class="tw-w-full tw-flex-[1] tw-rounded-[8px] tw-min-h-[76px]"></textarea>
      </div>
      <div class="tw-flex tw-mb-[22px] tw-py-[21px] tw-justify-center tw-align-center">
        <button class="tw-bg-[#009FE3] tw-text-white tw-rounded-[10px] tw-min-w-[150px] tw-h-[50px]" @click="sendPoll()">ENVIAR</button>
      </div>
      <div class="tw-flex tw-mb-[22px] tw-rounded-[10px] tw-py-[21px] tw-text-14 tw-justify-center tw-align-center">
        <p class="tw-mb-[45px]">¡Gracias a vuestra ayuda y a vuestro tiempo podremos mejorar día a día!</p>
      </div>
      <div class="tw-flex tw-justify-center tw-align-center">
        <img src="@/assets/img/poll/footer.svg" />
      </div>
    </div>
    
  </template>
<script>
import RadioButton from '@/components/surveys/RadioButton.vue'
import InputRangeSlider from '@/components/surveys/inputRangeSlider.vue'
import SrvSurveys from '@/services/surveys.js'
export default {
    components: { RadioButton, InputRangeSlider},
    data(){
        return {
          idPoll: null,
          questions: null,
          questionNPS: null,
          nameCenter: null,
          nameClient: null,
          comment: null,
          nps: 0,
          inputRangeData: [{ bottomLabel: "No" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "Sí" }]

        }
    },
    async created() {
      const res = await SrvSurveys.getPoll()
      if (res.status === 200) {
        this.idPoll = res.data.id
        this.questions = res.data.questions.filter(q => !q.is_nps)
        this.questions.map(e => {
          e.res = ''
        })
        this.questionNPS = res.data.questions.filter(q => q.is_nps)[0]
      }
    },
    methods: {
      async sendPoll(){
        const res = {poll: null, client: null, center: null, comment: null, answers: null}
        res.client = this.nameClient
        res.center = this.nameCenter
        res.comment = this.comment
        res.poll = this.idPoll
        const answers = []
        this.questions.forEach((q) => {
          answers.push({question: q.id, value: q.res})
        })
        answers.push({question: this.questionNPS.id, value: this.nps})
        res.answers = answers
        const resPost = await SrvSurveys.setPoll(res)
        console.log(resPost)
      }
    },
}
</script>
  <!--
  <script>
  const resQ1 = ref()
  const resQ2 = ref()
  const resQ3 = ref()
  const resQ4 = ref()
  const resQ5 = ref()
  const resQ6 = ref()
  const inputRangeData = ref([{ bottomLabel: "No" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "" }, { bottomLabel: "Sí" }])
  const nps = ref(0)
  watch(resQ1, () => {
    console.log(resQ1.value)
  })
  onBeforeMount(async () => {
    const { data } = await useFetch('https://dummyjson.com/products/1')
    console.log(data.value)
  })
  onMounted(async () => {
    const { data } = await useFetch('https://dummyjson.com/products/1')
    console.log(data.value)
  })
  </script>
  -->
<style lang="scss" scoped>
@media (min-width: 1024px) {
  .tw-lg-flex{
    display: flex;
  }
  .tw-lg-mt-0{
    margin-top:0;
  }
  .tw-lg-ml-24px{
    margin-left: 24px;
  }
  .tw-lg-max-w-421px{
    max-width: 421px;
  }
  .tw-lg-max-w-512px{
    max-width: 512px;
  }
  .tw-lg-px-0{
    padding-left: 0;
    padding-right: 0;
  }
  .tw-lg-ml-41px{
    margin-left: 41px;
  }
  .tw-lg-pr-0{
    padding-right: 0;
  }
}
</style>
  