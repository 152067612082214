<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog
      .modal-content(class="!tw-rounded-[10px]")
        .modal-header(class="")
          img(:src="img.head")
          button(@click="next()" type="button" class="close" data-dismiss="modal" ref="close")
            img(:src="img.close" width="25")

        .modal-body
          div
            p.tw-text-center(class="tw-text-[22px] tw-mt-[31px] tw-mb-[22px]") ¿Nos ayudas a mejorar?
            p.tw-text-center(class="tw-text-[14px]") Nuestra prioridad siempre son nuestros clientes por eso sería de mucha ayuda conocer tu opinión sobre nuestros servicios

        .modal-footer.tw-flex.tw-justify-center.tw-mt-5(class="tw-mb-[40px]")
          button(@click="next()" class="tw-px-[30px] tw-border tw-border-[#979797] tw-h-[42px] tw-flex tw-items-center tw-w-[126px] tw-mr-[38px]") #[translate Ahora no]
          a(href="https://pedidos.prismasl.com/surveys/poll" target="_blank"  class="tw-no-underline tw-px-[30px] tw-bg-[#009FE3] tw-text-[#fff] tw-w-[126px] tw-h-[42px] tw-flex tw-items-center") #[translate Sí, vamos]
    span(class="hidden") #[translate xxx#/]
</template>
<script>
export default {
  name: 'ModalCompletePoll',
  props: ['id'],
  data () {
    return {
      viewGrid: 'grid',
      img: {
        close: require('@/assets/img/icos/x_cerrar.svg'),
        head: require('@/assets/img/poll/headCompletePoll.png')
      },
    }
  },
  computed: {
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-complete-poll'
    }
  },
  created () {
  },
  methods: {
    next () {
      this.$refs.close.click()
    },    
  }
}
</script>
<style lang="scss" scoped>
.modal{
  max-width:460px;
  margin: 0 auto;
}
.modal-dialog{
  max-width: 100%;
  width: 460px;
}
.modal-content{
  border: none;
  @include roundAll(0);
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
  box-shadow: 0 3px 6px rgba(0,0,0,.25);
  overflow: hidden;
  .catalog-GridItem{
    float: left;
  }
}
.modal-header{
  padding:0;
  background-color:#EAEDED;
  border:0;
  .text-center{
    text-align: center;
  }
  .dibujo{
    position:relative;
  }
  p{
    display: inline-block;
    font-size: 14px;
  }
  .close{
    margin-top: 0;
    right: 24px;
    font-size: 40px;
    opacity: 1;
    position: absolute;
    top: 16px;
  }
}

.modal-body{
  max-width: 100%;
  padding: 0;
  font-size: 16px;
  font-family: "Roboto", Arial, Verdana,serif;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;

  .catalog-GridItem{
    margin-bottom: 0;
    width: 100%;

    .img-cn{
      float:left;
      width: 40%;
    }
    .text-center{
      float:right;
      width: 60%;
    }
    .code-cn{
      float:right;
      width: 60%;
    }
  }
}

.modal-footer{
  background-color:#fff;
  text-align: center;
  border-top: none;
}

h2{
  font-size: 18px;
  margin: 0;
}

.product-list {
  display: grid;
  gap: 0;
  grid-template-columns: repeat(4, 1fr);
}
</style>
