<template>
  <div class="slidecontainer">
    <div ref="tickmarks" class="tickmarks">
      <p v-for="(tick, index) in range" :key="index">
        <span class="top-label" :class="{ 'top-label-selected': modelValue === index, 'red': index >= 0 && index < 4, 'orange': index >= 4 && index <= 6, 'green': index >= 7 && index <= 10 }">
          {{ tick.topLabel ? tick.topLabel : index }}
        </span>
        <span class="tick-wrapper">
          <span class="tick tick-behind"></span>
        </span>
        <span class="bottom-label">{{ tick.bottomLabel }}</span>
      </p>
    </div>
    <input ref="input" :value="modelValue" type="range" min="0" max="10" step="1" class="slider" style="--val: 0; --max: 10; --min: 0" @input="onInput" />
  </div>
</template>

<script>
export default {
  props: {
    range: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      input: null,
      tickmarks: null
    }
  },
  methods: {
    manageTicks(value) {
      if (!this.$refs.tickmarks) return
      const children = this.$refs.tickmarks.children

      // Set range progress
      this.$refs.input.style.setProperty("--val", String(value))

      // Update tick classes
      for (let i = 0; i < this.range.length; i++) {
        const tickWrapper = children[i].querySelector(".tick-wrapper")
        const tick = tickWrapper.querySelector("span")
        tick.className = ""
        tickWrapper.classList.remove("tick-color")

        if (value > i) {
          tickWrapper.classList.add("tick-color")
          tick.classList.add("tick-behind")
        } else if (value < i) {
          tick.classList.add("tick")
        }

        if (value === 4 || value === 5 || value === 6) {
          this.$refs.input.style.setProperty("--slider-track-color", '#EEAC12')
        } else  if (value === 7 || value === 8 || value === 9 || value === 10) {
          this.$refs.input.style.setProperty("--slider-track-color", '#66C744')
        } else {
          this.$refs.input.style.setProperty("--slider-track-color", '#FF0000')
        }
      }
    },

    onInput() {
      this.$nextTick(()=>{
        this.manageTicks(this.$refs.input.value)
      })
      
      if (this.$refs.input && this.$refs.input.value) {
        this.$emit("update:modelValue", parseInt(this.$refs.input.value))
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.manageTicks(0)
    })
    
  },
  watch: {
    modelValue(newValue){
      this.manageTicks(newValue)
    }
  }
}
/*
export default defineComponent({
  name: "InputRangeSlider",
  components: {},
  props: {
    data: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Number,
      default: 0
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const input = ref()
    const tickmarks = ref()

    watch(
      () => props.modelValue,
      (newValue: number) => {
        nextTick(() => {
          manageTicks(newValue)
        })
      },
      { immediate: true }
    )

    const manageTicks = (value: number) => {
      if (!tickmarks.value) return

      const children = tickmarks.value.children

      // Set range progress
      input.value.style.setProperty("--val", String(value))

      // Update tick classes
      for (let i = 0; i < props.data.length; i++) {
        const tickWrapper = children[i].querySelector(".tick-wrapper")
        const tick = tickWrapper.querySelector("span")
        tick.className = ""
        tickWrapper.classList.remove("tick-color")

        if (value > i) {
          tickWrapper.classList.add("tick-color")
          tick.classList.add("tick-behind")
        } else if (value < i) {
          tick.classList.add("tick")
        }

        if (value === 4 || value === 5 || value === 6) {
          input.value.style.setProperty("--slider-track-color", '#EEAC12')
        } else  if (value === 7 || value === 8 || value === 9 || value === 10) {
          input.value.style.setProperty("--slider-track-color", '#66C744')
        } else {
          input.value.style.setProperty("--slider-track-color", '#FF0000')
        }
      }
    }

    const onInput = () => {
      emit("update:modelValue", parseInt(input.value.value))
    }

    return {
      input,
      tickmarks,
      onInput
    }
  }
})*/
</script>

<style scoped>
input:focus {
  outline: none;
}

.red {
  color: #FF0001 !important;
}

.orange {
  color: #EEAC12 !important;
}

.green {
  color: #66C744 !important;
}

.slider {
  -webkit-appearance: none;

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * 3.5em + var(--ratio) * (100% - 3.5em));
  --slider-track-color: #FF00;

  margin: 0;
  padding: 0;
  width: 100%;
  height: 1.5em;
  background: transparent;
  border: none;
}

.slider,
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.slider::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 20px;
  border-radius: 20px;
  z-index: 1;
  background: linear-gradient(var(--slider-track-color), var(--slider-track-color)) 0 / var(--sx) 100% no-repeat #ccc;
}

.slider::-moz-range-track {
  box-sizing: border-box;
  border: none;
  height: 20px;
  background: #e8e8e8;
  border-radius: 20px;
}

.slider::-moz-range-progress {
  height: 20px;
  background: #eeac12;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.slider::-webkit-slider-thumb {
  margin-top: -0.9em;
  box-sizing: border-box;
  border: solid 2px var(--slider-track-color);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: white;
}

.slider::-moz-range-thumb {
  margin-top: -0.9em;
  box-sizing: border-box;
  border: solid 2px #c51212;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: white;
}

.tickmarks {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5em;
}

.tickmarks p {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 1px;
  background: transparent;
  height: 14px;
  font-size: 14px;
  line-height: 10px;
  margin: 0 0 20px 0;
}

.tick {
  position: absolute;
  user-select: none;
  background: #70707033;
  height: 40px;
  width: 1px;
}

.tick-behind {
  background-color: var(--slider-track-color);
  height: 20px;
  width: 3px;
  position: absolute;
  left: -1px;
}

.tick-wrapper {
  pointer-events: none;
  width: 1px;
  height: 40px;
  background: transparent;
  display: flex;
  position: absolute;
  top: 26px;
  align-items: center;
}

.bottom-label {
  position: absolute;
  top: 85px;
  font-size: 16px;
  font-weight: 500;
}

.tick-color {
  background: #70707033;
}

.top-label {
  color: #66c744;
  transition: all 0.1s;
}

.top-label-selected {
  font-size: 1.5em;
  font-weight: 500;
  margin-top: -2px;
  opacity: 0.8;
}
</style>
