<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog
      .modal-content
        .modal-header
          p #[translate ¿Qué es el Índice Orbita de Sostenibilidad?]&nbsp;
          button(@click="next()" type="button" class="close")
            img(:src="img.close" width="25")
          span.hidden(@click="next()" type="button" class="close" data-dismiss="modal" ref="close")
            img(:src="img.close" width="25")
        .modal-body
            .tw-flex(class="tw-border-b tw-border-[#ABC7F1] tw-mb-[20px] tw-pb-[20px]")
              div(class="tw-w-[168px]")
                img(:src="img.modal")
              div(class="tw-border-r-[6px] tw-border-[#E1EDFE] tw-pr-10 tw-mr-10") 
              div(class="tw-w-[554px] text-[14px]")
                p Este indicador evalúa el aspecto medioambiental de los productos. Cada una de las categorías que actualmente están evaluadas se rigen por una serie de criterios. A continuación, se exponen los aplicados a cada categoría:
                ul
                  li #[strong Celulosas:] Los criterios escogidos han sido la presencia o no de ecoetiquetas y su tipo, y los porcentajes de fibras, tanto recicladas como vírgenes.
                  li #[strong Desechables:] Los criterios han sido la presencia o no de ecoetiquetas, el material del cual el producto está hecho, la presencia o no de materiales secundarios en el producto y cuál es, y si es reciclado o no.
                  li #[strong Químicos:] Los criterios son el material del envase, la presencia o no de ecoetiquetas y su tipo, y si es un producto concentrado o no.

                p El objetivo es conocer, con un indicador simple, cómo de medioambientalmente respetuoso ha sido el proceso de manufactura y la posterior disposición sostenible de estos productos, atendiendo a estos estándares.

    span(class="hidden") #[translate xxx#/]
</template>
<script>
import * as types from '@/store/mutation-types'
import BkItem from '@/components/catalog/Item-OtherProducts.vue'
export default {
  name: 'ModalImage',
  props: ['id', 'title', 'content', 'imagen', 'aProduct'],
  components: {BkItem},
  data () {
    return {
      viewGrid: 'grid',
      img: {
        close: require('@/assets/img/icos/x_cerrar.svg'),
        modal: require('@/assets/img/modal-soste.svg')
      },
      migas: [],
      countOthersProductsAdds: 0
    }
  },
  computed: {
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-others-products'
    }
  },
  created () {
  },
  methods: {
    next () {
      this.$refs.close.click()
      this.$store.commit(types.MODAL_INFO_SOSTE_VIEW, false)
      
    },
  }
}
</script>
<style lang="scss" scoped>
  .modal{
    max-width:830px;
    margin: 0 auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .modal-dialog{
    max-width: 830px;
    width: max-content;
  }
  .modal-content{
    border: none;
    @include roundAll(10);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
    .catalog-GridItem{
      float: left;
    }
  }
  .modal-header{    
    padding:0;
    background-color:#E1EDFE;
    border:0;
    position:relative;
    padding-top:20px;
    padding-left: 18px;
    padding-bottom:20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .text-center{
      text-align: center;
    }
    p{
      font-size: 18px;
    }
    .close{
      margin-top: 0;
      right:20px;
      font-size: 40px;
      opacity: 1;
      position: absolute;
      top:10px;
    }
  }

  .modal-body{
    width: max-content;
    max-width: 830px;
    min-width: 540px;
    padding: 20px;
    font-size: 14px;
    font-family: "Roboto", Arial, Verdana;
    max-height: 356px;
    .product-list{
      padding: 0;
    }
    .row{
      margin-right: 0;
      margin-left: 0;
      padding: 0;
    }
    .catalog-GridItem{
      border: 1px solid #EAEDED;
      margin-bottom: 0;
      width: 270px;
      .img-cn{
        float:left;
        width: 40%;
      }
      .text-center{
        float:right;
        width: 60%;
      }
      .code-cn{
        float:right;
        width: 60%;
      }
    }
  }

  .modal-footer{
    background-color:#EAEDED;
    text-align: center;
    button{
      background-color: #FF7343;
      color:#fff;
      padding:10px 30px;
      border:none;
    }
  }

  h2{
    font-size: 18px;
    margin: 0;
  }

  .container{
    max-width: 830px;
    width: max-content;
  }
</style>
