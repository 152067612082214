<template lang="pug">
  .ofertas-validar
    // Mostramos listado
    div(v-if="$route.name !== 'validarOfertasSingle'")
      .box-2
        .container
          .row
            .col-sm-8
              bk-search(@toSearch="toSearch" :num="offersNum" v-if="offersNumPendientes || offersNum")
            .col-sm-4.text-right

      // Listado Pedidos Pendientes
      .box-3(v-if="offersNumPendientes")
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Ofertas pendientes de validar]
                small(:class="{'fade-in': offersNumPendientes}")  ({{offersNumPendientes}} #[translate Ofertas])
            .col-sm-4.text-right

      bk-grid(
        :orders="aOffersPendientes"
        typeGrid="pendientes"
        :message="message"
        pages="0"
        :count="offersNumPendientes"
        currentPage="1")

      // Listado Ofertas validados
      .box-3(v-if="offersNum")
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Ofertas validadas]
                small(:class="{'fade-in': offersNum}")  ({{offersNum}} #[translate Ofertas])
            .col-sm-4.text-right
              date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="date" :first-day-of-week="calendar.firstDayOfWeek" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)

      bk-grid(
        :orders="aOffers"
        typeGrid="validados"
        :message="message"
        :pages="totalPages"
        :count="offersNum"
        :currentPage="currentPage"
        @toPaginate="pagChange")

    // Mostramos el detalle de la oferta.
    div(v-if="$route.name == 'validarOfertasSingle'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>
<script>
import appConfig from '@/config-app.js'
import BkSearch from '@/components/validar-ofertas/Search.vue'
import BkGrid from '@/components/validar-ofertas/Listado.vue'
import DatePicker from 'vue2-datepicker'
// importamos servicios Catálogo
import SrvValidarOfertas from '@/services/validar-ofertas'

export default {
  name: 'OfertasValidar',
  props: [],
  components: { BkSearch, BkGrid, DatePicker },
  data () {
    return {
      'aOffers': '', // ofertas validados.
      'offersNum': null, // Num de ofertas validados.
      'aOffersPendientes': '', // ofertas no validados.
      'offersNumPendientes': null, // Num de ofertas no validados.
      'message': '',
      'totalPages': 0,
      'init': false, // Para comprobar si es la primera vez que llamamos a la API
      'currentPage': 0, // Para saber porque página vamos en la paginación
      'query': '', // Donde se guarla la palabra a buscar.
      'calendar': {
        'before': this.$moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().add({'days': -30}).format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().add({'days': -30}).format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY', // 'MMM YYYY'
        'firstDayOfWeek': 1
      }
    }
  },
  created () {
    this.getOfertasPendientes()
    this.query = ''
    this.currentPage = 1
    this.search()
  },
  computed: {
    country () {
      return this.$store.getters.userInfo.country
    },
    center () {
      return this.$store.getters.center
    },
    client () {
      return this.$store.getters.client
    },
    validable_offers () {
      // TODO: Número de ofertas pendientes.
      return this.$store.getters.userInfo.validable_offers
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.search()
    },
    toSearch (query) {
      this.query = query
      this.currentPage = 1
      this.search()
    },
    search (query, page) {
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvValidarOfertas.getOfertasValidadas(this.query, this.currentPage, this.calendar.before, this.calendar.after)
        .then(res => {
          if (res.status === 200) {
            this.aOffers = res.data.results
            this.offersNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
            // console.log(`offersNum: ${this.offersNum}`)
            // console.log('order:', res.data.results[0])
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
        this.getOfertasPendientes()
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after} - ${this.calendar.before}`)
        this.search()
      }
    },
    getOfertasPendientes () {
      SrvValidarOfertas.getOfertasPendientes('', 1)
        .then(res => {
          if (res.status === 200) {
            this.aOffersPendientes = res.data.results
            this.offersNumPendientes = res.data.count
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    country () {
      // actualizamos el valor de los pedidos pendientes.
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    center () {
      // console.log('cambio center:', this.center)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    client () {
      // console.log('----cambio cliente:', this.client)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    validable_offers () {
      // console.log('watch número de pedidos.')
      this.getOfertasPendientes()
    }
  }
}
</script>
<style lang="scss" scoped>
.box-2{
  padding: 14px 0;
  background-color: #EFEFEF;
}

.box-3{
  padding: 26px 0 20px;

  .row{
    display: flex;
    align-items: center;
  }

  .info-cn{
    font-size: 16px;
    margin: 0;

    small{
      font-size: 16px;
    }
  }
}
</style>
