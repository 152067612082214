<template lang="pug">
  div
    span.bg(:style="imgBg")
    .form-cn(:class="'type-' + type_app")
      .box-info(v-if="type_app === 'prisma'")
        p.img-cn.tw-flex.tw-justify-center
          img(:src="imgMobile")
        .info-cn
          p #[translate Si todavía no conoces nuestra aplicación de pedidos y quieres acceder a la información más completa de producto y realizar tus pedidos en cualquier momento desde cualquier lugar y dispositivo, solicita tu clave de acceso]
          p
            a(href="https://www.prismasl.com/contacto/" target="_blank" class="btn btn-action") #[translate Solicitar mi Clave]
      h2.tw-flex.tw-justify-center
        img(:src="imgHead" width="280")

      p.msg-error(v-if="errorMsgShow" :class="{ 'show':errorMsgShow }")
        // i.fa.fa-info-circle(aria-hidden="true")
        span {{errorMsg}}

      form
        p
          input(type="email" v-model="email" :placeholder="placeholder.email" :class="{ 'error':errorEmail }" @focus="resetError")
        p
          input(type="password" v-model="password" :placeholder="placeholder.pwd" :class="{ 'error':errorPwd }" @focus="resetError")
        p
          a(:href="page_password_reset") #[translate ¿Has olvidado tu contraseña?]
        p
          button.btn.btn-action( type="button" @click="send" @keyup.enter="send") #[translate INICIAR SESIÓN]
    div.ft-cn(v-if="type_app !== 'inpacs' && type_app !== 'vario'")
      p
        img(:src="imgFooter")
        translate(v-if="this.type_app !== 'orbishigiene' && type_app !== 'ghgorbis'") Copyright Orbis Higiene Global SL {{year}}
</template>
<script>
// importamos servicios
import SrvUsu from '@/services/users'
import jwtDecode from 'jwt-decode'

export default {
  name: 'Auth',
  data () {
    return {
      imgBg: `background-image:url(${require('@/assets/img/login-bg.png')})`,
      imgMobile: require('@/assets/img/login-mobil.png'),
      email: null,
      password: null,
      errorMsg: false,
      errorEmail: false,
      errorPwd: false,
      errorMsgShow: false,
      placeholder: {
        'email': this.$gettext('Email'),
        'pwd': this.$gettext('Contraseña')
      },
      page_password_reset: this.$config.page_password_reset
    }
  },
  created () {
    window.localStorage.removeItem('_client')
    window.localStorage.removeItem('_center')
    // console.log('--- ' + this.$config.type + ' // ' + this.$config.page_password_reset)

    // añadir código de analytics.
    if (this.config_app.id_ga) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.config_app.id_ga}`
      script.async = true
      document.head.appendChild(script)

      const scriptLine = document.createElement('script')
      // scriptLine.type = 'text/javascript'
      scriptLine.text = `window.dataLayer = window.dataLayer || [];
                         function gtag(){dataLayer.push(arguments);}
                         gtag('js', new Date()); gtag('config', '${this.config_app.id_ga}');`

      document.head.appendChild(scriptLine)
    }
    // Verificamos si viene el token en query string. Si es asi intentamos
    // hacer el login con ese token.
    let poToken = this.urlParam('token')
    let buyerCookie = this.urlParam('buyer_cookie')
    if (poToken && buyerCookie) {
      var tokenData = jwtDecode(poToken)
      this.email = tokenData.email
      this.password = tokenData.email
      window.localStorage.setItem('_token', poToken)
      window.localStorage.setItem('_buyerCookie', buyerCookie)
      this.autoLogin()
    }
  },
  computed: {
    imgHead () {
      const extension = (this.type_app === 'inpacs' || this.type_app === 'vario') ? 'svg' : 'png'
      return require(`@/assets/img/themes/${this.$config.type}/logo-login.${extension}`)
    },
    imgFooter () {
      const img = (this.type_app === 'orbishigiene' || this.type_app === 'ghgorbis') ? 'themes/orbishigiene/logo-login-footer.png' : 'l-orbis.png'
      return require(`@/assets/img/${img}`)
    },
    year () {
      return (new Date()).getFullYear()
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    send () {
      let email = this.email
      let pwd = this.password
      this.resetError()

      let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      if (email === null || email === '' || !email.match(RegEx)) {
        this.errorMsg = true
        this.errorEmail = true
      }

      if (pwd === null || pwd === '') {
        this.errorMsg = true
        this.errorPwd = true
      }

      if (!this.errorMsg) {
        this.$store.dispatch('login', {email: email, password: pwd})
          .then(res => {
            // console.log('token devuelve el login ----->', res.data.token)
            window.localStorage.setItem('_token', res.data.token)
            this.autoLogin()
          }, (error) => {
            // console.log('Error login: ----->', error.data.non_field_errors[0])
            this.errorMsgShow = true
            if (error.data.non_field_errors[0]) {
              this.errorMsg = error.data.non_field_errors[0]
            } else {
              this.errorMsg = this.$gettext('¡Error! datos incorrectos')
            }
          })
      }
    },
    resetError () {
      this.errorMsgShow = false
      this.errorEmail = false
      this.errorPwd = false
      this.errorMsg = false
    },
    autoLogin () {
      this.$store.commit('PENDING') // Para mostrar el preload
      window.localStorage.removeItem('recurringOrder')
      SrvUsu.getDetails()
        .then(res => {
          if (res.status === 200) {
            const user = res.data
            this.$store.dispatch('setUser', user)
            this.$store.dispatch('deleteCart')
            this.$store.dispatch('cleanEditOrderPending')
            this.$store.dispatch('cleanEditOfferPending')
            this.$store.commit('LOGIN_SUCCESS')
            this.$store.commit('NOT_PENDING') // Para quitar el preload
            if (user.clients_count === 1) {
              // si tiene unn cliente, le envíamos al listado de cliente para que se seleccione automáticamente.
              this.$router.push({name: 'clientList'})
            } else {
              // si tiene muchos clientes le llevamos a la home que tenga definida.
              this.$router.push({name: this.config_app.url_home})
            }
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    urlParam (name) {
      var results = new RegExp('[?&amp;]' + name + '=([^&#]*)').exec(window.location.search)
      if (results == null) {
        return null
      } else {
        return decodeURI(results[1]) || 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  .bg{
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: #f2f7fa;
  }

  .msg-error{
    // position:absolute;
    width:100%;
    color:$red;
    font-size:14px;
    text-align: center;
    padding:10px;
    background-color: rgba($red, 0.1);
    @include transition();
    @include opacity(0);

    &.show{
      @include opacity(1);
    }

    i{
      display:block;
      margin-bottom: 4px;
      font-size: 18px;
    }
  }

  .form-cn{
    position:relative;
    max-width: 620px;
    background-color: $white;
    margin:20px auto 50px;
    @include roundAll(6);

    &.type-prisma{
      margin:20px auto 250px;
    }

    @include breakpoint(large){
      margin:20px auto 36px;
    }

    .box-info{
      position: absolute;
      bottom: -214px;
      left:0;
      right: 0;
      text-align: center;
      @include roundAll(4);
      overflow: hidden;

      @include breakpoint(large){
        top: 0;
        bottom: auto;
        left:auto;
        right: -286px;
        width: 280px;
      }

      .img-cn{
          display: none;
          @include breakpoint(large){
            display: flex;
            background-color: #dfeef8;
            text-align: center;
            padding: 16px 20px 10px;
            margin-bottom: 0;
          }
      }

      .info-cn{
        background-color: $white;
        padding: 34px 30px 26px;
      }

      p{
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }

      .btn{
        max-width: 400px;
        margin: 0 auto;
        padding: 10px 16px;
      }
    }

    form{
      max-width: 440px;
      margin:0 auto;
      padding:20px 20px 14px;

      p{
        margin-bottom:34px;
      }

      a{
        color:$blue-light;
        @include transition();

        &:hover, &:focus{
          color:darken($blue-light, 10%);
        }

      }
    }

    .btn{
      display:block;
      width:100%;
      padding:16px;
    }
  }

  input{
    display:block;
    width: 100%;
    border:none;
    font-size:18px;
    border-bottom:2px solid #494f58;
    padding:16px 10px;
    @include transition();

    &::-webkit-input-placeholder {color: $black;opacity: .40;-moz-opacity: .40;filter:alpha(opacity=40);}
    &:-moz-placeholder {color: $black;opacity: .40;-moz-opacity: .40;filter:alpha(opacity=40);}
    &::-moz-placeholder {color: $black;opacity: .40;-moz-opacity: .40;filter:alpha(opacity=40);}
    &:-ms-input-placeholder {color: $black;opacity: .40;-moz-opacity: .40;filter:alpha(opacity=40);}

    &:focus{
      border-color:#2f8aff;

      &::-webkit-input-placeholder {color: #2f8aff;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
      &:-moz-placeholder {color: #2f8aff;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
      &::-moz-placeholder {color: #2f8aff;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
      &:-ms-input-placeholder {color: #2f8aff;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
    }

    &.error{
      border-color:$red;
      color:$red;
      background-color: transparent;

      &::-webkit-input-placeholder {color: $red;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
      &:-moz-placeholder {color: $red;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
      &::-moz-placeholder {color: $red;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
      &:-ms-input-placeholder {color: $red;opacity: .60;-moz-opacity: .60;filter:alpha(opacity=60);}
    }



  }

  h2{
    text-align:center;
    padding:24px 0 26px;
    border-bottom:2px solid #f2f7fa;
    margin:0;
  }

  .ft-cn{
    position:relative;
    max-width: 620px;
    margin:0 auto;
    text-align: center;

    img{
      margin:0 auto 18px;
      display:block;
    }

    span{
      color:#b8c0c4;
      font-size:12px;
    }
  }

</style>
