<template>
  <table role="table" class="tw-min-w-full tw-divide-y tw-divide-gray-200">
    <thead class="tw-bg-white">
    <tr role="row" class="tsm-ranking-table-header">
      <th scope="col" class="tw-select-none tw-border-r last:tw-border-0 tw-group tw-pl-3 tw-pr-2 tw-w-[240px] tw-max-w-[240px] tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader">
        <div class="tw-flex tw-justify-between tw-text-[14px] tw-font-light tw-uppercase">Nº Centro</div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">pedido MEDIO (€)</div>
          <div class="tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" :class="{'tw-rotate-180': ordering === '-total_orders_amount_avg'}" :src="orderActive === 'total_orders_amount_avg' ? IcoOrderActive : IcoOrder" 
            @click="$emit('setOrdering', ordering === '-total_orders_amount_avg' ? 'total_orders_amount_avg' : '-total_orders_amount_avg')" alt="Filter">
          </div>
        </div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">Nº Dias 
entre pedidos</div>
          <div class="tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" :class="{'tw-rotate-180': ordering === '-days_between_orders_avg'}" :src="orderActive === 'days_between_orders_avg' ? IcoOrderActive : IcoOrder" 
            @click="$emit('setOrdering', ordering === '-days_between_orders_avg' ? 'days_between_orders_avg' : '-days_between_orders_avg')" alt="Filter">
          </div>
        </div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">Frecuencia 
pedidos SEMANA</div>
          <div class="tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" :class="{'tw-rotate-180': ordering === '-orders_frequency_by_week'}" :src="orderActive === 'orders_frequency_by_week' ? IcoOrderActive : IcoOrder" 
            @click="$emit('setOrdering', ordering === '-orders_frequency_by_week' ? 'orders_frequency_by_week' : '-orders_frequency_by_week')" alt="Filter">
          </div>
        </div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">Frecuencia 
pedidos mes </div>
          <div class="tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" :class="{'tw-rotate-180': ordering === '-orders_frequency_by_month'}" :src="orderActive === 'orders_frequency_by_month' ? IcoOrderActive : IcoOrder" 
            @click="$emit('setOrdering', ordering === '-orders_frequency_by_month' ? 'orders_frequency_by_month' : '-orders_frequency_by_month')" alt="Filter">
          </div>
        </div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">Emisiones 
Total Kg CO2e</div>
          <div class="tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" :class="{'tw-rotate-180': ordering === '-co2e'}" :src="orderActive === 'co2e' ? IcoOrderActive : IcoOrder" 
            @click="$emit('setOrdering', ordering === '-co2e' ? 'co2e' : '-co2e')" alt="Filter">
          </div>
        </div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">Total pedidos</div>
          <div class="tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" :class="{'tw-rotate-180': ordering === '-orders_count'}" :src="orderActive === 'orders_count' ? IcoOrderActive : IcoOrder" 
            @click="$emit('setOrdering', ordering === '-orders_count' ? 'orders_count' : '-orders_count')" alt="Filter">
          </div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody role="rowgroup" class="tw-bg-white tw-divide-y tw-divide-gray-200">
      <tr v-if="data && data.length" v-for="(item, index) in data" role="row" class="odd:tw-bg-[#FAFBFC] even:tw-bg-white tsm-ranking-table-rows">
      <td role="cell" class=" tw-font-light tw-flex tw-gap-3 last:tw-border-0 tw-border-r tw-pl-3 tw-pr-2 tw-py-2 tw-w-[240px] tw-max-w-[240px] tw-break-words">
        <span>{{ index + 1 }}</span>
        <span class="tw-uppercase">{{ item.center }}</span>
      </td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item.total_orders_amount_avg | formatMoney(2,2)}} </td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item.days_between_orders_avg | formatMoney(2,2) }}</td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item.orders_frequency_by_week | formatMoney(2,2) }}</td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item.orders_frequency_by_month | formatMoney(2,2) }}</td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item.co2e | formatMoney(2,2)}} t CO2</td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item.orders_count}}</td>
    </tr>
      <tr v-show="data && data.length === 0" role="row" class="odd:tw-bg-[#FAFBFC] even:tw-bg-white tsm-ranking-table-rows">
        <td role="cell" class=" tw-font-light tw-flex tw-gap-3 last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-w-[240px] tw-max-w-[240px] tw-break-words">
          <span>Sin datos</span>
          <span class="tw-uppercase"></span>
        </td>
        <td role="cell" class="tw-text-right tw-font-light last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap"></td>
        <td role="cell" class="tw-text-right tw-font-light last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap"></td>
        <td role="cell" class="tw-text-right tw-font-light last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap"></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'ranking-table',
  props: ['data', 'ordering'],
  data(){
    return {
      IcoOrderActive: require('@/components/pages/carbon-footprint/grid-arrow-up-active.svg'),
      IcoOrder: require('@/components/pages/carbon-footprint/source_icons_arrow.svg')
    }
  },
  computed: {
    orderActive(){
      return this.ordering.replace('-','')
    }
  }
}
</script>
