<template lang="pug">
  .albaran-grid
    .Loader-cn(:class="{'show': isPdfLoading}")
      span.Loader-spinner
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}

    .container(v-if="count == 0")
      .row
        .col-sm-12
          div(class="alert alert-info" role="alert") #[translate No se han encontrado albaranes].

    .holder-cn(v-if="count")
      .container
        .row.grid-head.headAlbaran
          .col-sm-2
            p.name #[translate Nombre del Centro]
          .col-sm-2
            p.text-right #[translate Núm. albarán]
          .col-sm-2
            p.text-center #[translate Facturable]
          .col-sm-1
            p.text-center #[translate Fecha]
          .col-sm-2
            p.text-right(v-if="showPrice") #[translate Total]
            p.text-right(v-else) &nbsp;
          .col-sm-1
            p.text-center #[translate Ver albarán]
          .col-sm-1
            p.text-center #[translate Descargar]
          .col-sm-1
            button.tw-flex.tw-items-center.tw-gap-2.tw-px-2.tw-py-1( @click="downloadDeliveryNoteZip" class="tw-h-[21px]" :class="{'tw-bg-[#FAFAFA]': ordersChecked.length === 0 ,'tw-bg-[#EBF3FE]': ordersChecked.length > 0 }")
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <g id="Grupo_9506" data-name="Grupo 9506" transform="translate(-1309.5 -349.5)" opacity="0.68">
                  <g id="ico_pdf.0fa79e21" transform="translate(1310 349.5)">
                    <path id="Trazado_1254" d="M14,16.5H2a2.006,2.006,0,0,1-2-2V2.5a2.006,2.006,0,0,1,2-2H9.9L16,5.4v9.1A2.006,2.006,0,0,1,14,16.5Z" fill="#fff"/>
                    <g id="Grupo_9505" data-name="Grupo 9505">
                      <path id="Trazado_5635" data-name="Trazado 5635" d="M15.5,4.3,11,.4c-.2-.2-.4-.2-.7-.3,0-.1-.1-.1-.2-.1H1.8A1.746,1.746,0,0,0,0,1.5V7.8a.472.472,0,0,0,.5.5A.472.472,0,0,0,1,7.8V1.6a.52.52,0,0,1,.2-.4A1.072,1.072,0,0,1,1.8,1H9.7V5.2a.472.472,0,0,0,.5.5H15V7.9a.472.472,0,0,0,.5.5.56.56,0,0,0,.5-.6V5.4A1.606,1.606,0,0,0,15.5,4.3Zm-4.9.4V1.4l3.8,3.3Z" fill="#fc8003"/>
                    </g>
                  </g>
                  <text id="ZIP" transform="translate(1310 365)" fill="#fc8003" stroke="#fc8003" stroke-width="0.5" font-size="9" font-family="SegoeUI-Bold, Segoe UI" font-weight="700" letter-spacing="0.126em"><tspan x="0" y="0">ZIP</tspan></text>
                </g>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="10.454" height="11.81" viewBox="0 0 10.454 11.81">
              <g id="Grupo_9516" data-name="Grupo 9516" transform="translate(-1260.939 -302.5)">
                <g id="small-down" transform="translate(1262 309.083)">
                  <g id="Grupo_239" data-name="Grupo 239">
                    <line id="Línea_30" data-name="Línea 30" x2="4.167" y2="4.167" fill="none" :stroke="ordersChecked.length > 0 ? '#0099FF' : '#d8d8d8'" stroke-linecap="round" stroke-width="1.5"/>
                    <line id="Línea_31" data-name="Línea 31" x2="4.167" y2="4.167" transform="translate(8.333) rotate(90)" fill="none" :stroke="ordersChecked.length > 0 ? '#0099FF' : '#d8d8d8'" stroke-linecap="round" stroke-width="1.5"/>
                  </g>
                </g>
                <line id="Línea_1042" data-name="Línea 1042" y1="10" transform="translate(1266.167 303.25)" fill="none" :stroke="ordersChecked.length > 0 ? '#0099FF' : '#d8d8d8'" stroke-linecap="round" stroke-width="1.5"/>
              </g>
              </svg>


      .grid-cn(v-if="count")
        .container
          bk-item(v-for="(order, index) in orders" :key="order.id" :order="order" :index="index" :ordersSelected="ordersChecked" @updateOrdersChecked="ordersChecked = $event"  @setOnPdfLoading="setOnPdfLoading" @setOffPdfLoading="setOffPdfLoading")

      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")
      <ModalDescargar :show="showDownloading" @update:show="showDownloading = $event"></ModalDescargar>
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/albaran/Item.vue'
import ModalDescargar from '@/components/common/ModalDescargar.vue'
import SrvAlbaranes from '@/services/albaranes'
import commonMixins from '@/mixins/commons.js'

export default {
  name: 'OrderListGrid',
  mixins: [ commonMixins ],
  props: ['orders', 'message', 'pages', 'count', 'currentPage'],
  data () {
    return {
      isPdfLoading: false,
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      },
      ordersChecked: [],
      showDownloading: false
    }
  },
  components: { BkItem, EkPaginate, ModalDescargar },
  computed: {
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  created() {
    
        
  },
  methods: {
    async downloadDeliveryNoteZip () {
      this.showDownloading = true
      try {
        const ids = new URLSearchParams()
        this.ordersChecked.forEach(e => ids.append('ids', e))
        const { status, data } = await SrvAlbaranes.getAlbaranesZip(ids.toString())
        if (status === 200) {
          this.showDownloading = false
          const blob = new Blob([data], {type: 'application/zip'})
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `albaranes.zip`
          link.click()
          this.ordersChecked = []
        }
      } catch (e) {
        this.showDownloading = false
      } 
      
      //SrvAlbaranes.getAlbaranesZip(ids.toString())
    },
    setOnPdfLoading () {
      this.isPdfLoading = true
    },
    setOffPdfLoading () {
      this.isPdfLoading = false
    },
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    }
  },
  watch: {
    orders() {
      window.addEventListener('scroll', () => {
        if (document.querySelector('.headAlbaran')) {
          const header = document.querySelector('.headAlbaran');
          const sticky = header.classList.contains('fija');
          const scrollTop = window.pageYOffset;
          if (scrollTop > 150 && !sticky) {
            header.classList.add('fija');
          } else if (scrollTop < 150  && sticky) {
            header.classList.remove('fija');
          }
        }
    })
    }
  }
}
</script>
<style lang="scss" scoped>
.fija {
position: fixed;
width: 1230px;
z-index: 999;
top: 54px;
padding-top: 30px;
background-color: #fff;
}
.grid-head{
  margin-top: 6px;
  margin-bottom: 6px;
  & > *{
    padding-bottom: 6px;
    border-bottom: 1px solid #1C2C40;
    font-size: 14px;

    &:first-child{
      font-weight: $medium;
    }
  }

  p{
    margin: 0;
  }
}

.grid-cn{
  margin-bottom: 24px;

  & > *{
    padding:0;
  }

  .albaran-list-items{
    &:nth-child(even){
      background-color: rgba(#F1F4F7, .38);
    }

    &:last-child{
        border-bottom: 1px solid #1C2C40;
    }
  }
}

.alert{
  margin-top:40px;
}

</style>
