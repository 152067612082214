// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getReurrentOrders (querySearch, page, before, after) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      const oClient = Stores.getters.client
      const oCenter = Stores.getters.center

      const idClient = (oClient) ? oClient.id : ''
      const idCenter = (oCenter) ? oCenter.id : ''

      let countryTmp = Stores.getters.userInfo.country
      let country = (countryTmp && countryTmp.id !== 'eu') ? countryTmp.id : ''

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/recurrent_orders/?country=${country}&client=${idClient}&center=${idCenter}&search=${querySearch}&page=${page}&before=${before}&after=${after}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getRecurrentOrder (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API
      // console.log('Serv getOrder, localstorage: ', window.localStorage)
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/recurrent_orders/${id}/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  setRecurrentOrder ({observations, frequency, day, id = null}) {
    return new Promise((resolve, reject) => {
      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''
      let method = 'put'
      const query = `cart/create_update_recurrent_order/`

      let datos = {
        'client': idClient,
        'center': idCenter,
        'observations': observations,
        'recurrent_day': day,
        'recurrent_frequency': frequency
      }     
      if (id) {
        datos.recurrent_id = id
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios[method](query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          // console.log(`setOrder error: ${error.response.status}, error response:`, error.response.data)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error.response)
        })
    })
  },

  /*setCreateRecurrentOrder ({idOrder, frequency, day}) {
    return new Promise((resolve, reject) => {
      const query = `/orders/${idOrder}/create_recurrent_order/`
      let datos = {
        "recurrent_day": day,
        "recurrent_frequency": frequency
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.post(query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  setUpdateRecurrentOrder ({idOrder, frequency, day, details}) {
    return new Promise((resolve, reject) => {
      const query = `/recurrent_orders/${idOrder}/`
      let datos = {
        "recurrent_day": day,
        "recurrent_frequency": frequency

      }
      if (details) {
        datos.details = details
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },*/
  deleteRecurrentOrder (id) {
    return new Promise((resolve, reject) => {
      const query = `/recurrent_orders/${id}/`

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.delete(query)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  /*editRecurrentEntryOrder ({id, quantity}) {
    return new Promise((resolve, reject) => {
      const query = `/recurrent_orders/entry/${id}/`
      let datos = {
        "quantity": quantity,
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  deleteRecurrentEntryOrder (id) {
    return new Promise((resolve, reject) => {
      const query = `/recurrent_orders/entry/${id}/`

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.delete(query)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },*/
}
