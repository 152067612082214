<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog
      .modal-content
        .modal-header(style="padding: 1rem 2rem")          
          button(@click="next()" type="button" class="close")
            img(:src="img.close" width="25")
          button.hidden(@click="next()" type="button" class="close" data-dismiss="modal" ref="close")
            img(:src="img.close" width="25")
          div(class="tw-text-center")
            img(:src="img.recurrentes" class="tw-inline")
            p(class="tw-mt-3")
              strong(class="tw-text[#1C2C40] tw-font-medium") CREAR PEDIDO RECURRENTE

        .modal-body.tw-relative(v-if="!status")
          Loading.tw-absolute(:fixed="false" v-if="enviando")
          .product-list(class="tw-text-center tw-mt-9 tw-pt-6" :class="{'tw-opacity-50': enviando}")
              .dropdown.Select-bk(class="!tw-flex tw-items-center tw-justify-center !tw-w-[300px] !tw-mx-auto")
                span(class="tw-mr-1 tw-w-2/6 tw-text-left") #[translate Frecuencia]:
                button.form-control(class="btn btn-default dropdown-toggle tw-w-4/6" type="button" id="ddPrintFormats" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{frequencyActiveName}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddPrintFormats")
                  li(v-for="item in frequencies" :class="{'disabled': frequency == item.id}")
                    span(:value="item" @click="frequency = item.id") {{item.value}}
          div
            .product-list(class="tw-text-center tw-mt-5" :class="{'tw-opacity-50': enviando}")
              .dropdown.Select-bk(class="!tw-flex tw-items-center tw-justify-center !tw-w-[300px] !tw-mx-auto")
                span(class="tw-mr-1 tw-w-2/6 tw-text-left") #[translate Entrega cada]:
                button.form-control(class="btn btn-default dropdown-toggle tw-w-4/6" type="button" id="ddPrintFormats" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{day}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddPrintFormats")
                  li(v-for="item in frequenciesMonth" :class="{'disabled': day == item}")
                    span(:value="item" @click="day = item") {{item}}
        .modal-body(v-else)
          div(class="tw-text-center tw-mt-[90px] tw-mb-[25px]")
            strong {{ status }}
        .modal-footer(v-if="!status")
          button(@click="next()" class="!tw-border tw-border-[#979797]") #[translate Cancelar]
          button(@click="replicar({hasClosePopup: true, center})" class="btn btn-action !tw-border tw-border-[#64B77F] tw-bg-[#64B77F] tw-text-[#fff] tw-ml-2" :class="{'btn-send': enviando}") #[translate Guardar]
    span(class="hidden") #[translate xxx#/]
</template>
<script>
import * as types from '@/store/mutation-types'
import SrvOrder from '@/services/ordersRecurrent'
import MixinOrdersRecurrent from '@/mixins/ordersRecurrents.js'
import Loading from '@/components/common/Loading.vue'
export default {
  name: 'ModalImage',
  props: ['id', 'idOrder', 'aProduct', 'center'],
  components: { Loading },
  mixins: [MixinOrdersRecurrent],
  data () {
    return {
      img: {
        close: require('@/assets/img/icos/x_cerrar.svg'),
        recurrentes: require('@/assets/img/pedidoRecurrente.svg')
      },
      frequency: '1m',
      day: '01',
      status: null,
      enviando: false,
    }
  },
  computed: {
    frequencyActiveName () {
      return this.frequencies.filter(e => e.id == this.frequency)[0].value
    },
    client () {
      return this.$store.getters.client
    },
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-config-recurrent-order'
    }
  },
  created () {
  },
  methods: {
    next () {
      this.$refs.close.click()
    }
  }
}
</script>
<style lang="scss" scoped>
.modal{
  max-width:460px;
  margin: 0 auto;
}
.modal-dialog{
  max-width: 100%;
  width: 460px;
}
.modal-content{
  border: none;
  @include roundAll(0);
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
  box-shadow: 0 3px 6px rgba(0,0,0,.25);
  .catalog-GridItem{
    float: left;
  }
}
.modal-header{
  padding:0;
  background-color:#EBF3FE;
  border:0;
  .text-center{
    text-align: center;
  }
  .dibujo{
    position:relative;
  }
  .close{
    margin-top: 0;
    right: 10px;
    font-size: 40px;
    opacity: 1;
    position: absolute;
    top: 10px;
  }
}

.modal-body{
  max-width: 100%;
  padding: 0;
  font-size: 16px;
  font-family: "Roboto", Arial, Verdana,serif;
  height: 169px;
}

.modal-footer{
  background-color:#fff;
  border-top: 1px solid #EBF3FE;
  text-align: center;
  button{
    padding:10px 30px;
  }
}

h2{
  font-size: 18px;
  margin: 0;
}

</style>
