<template>
    <label class="wrapper tw-cursor-pointer tw-relative tw-w-fit">
        {{label}}
        <input class="tw-absolute tw-opacity-0 tw-h-0 tw-w-0 tw-cursor-pointer" type="radio" :name="name" :checked="isChecked" :value="value" @change="$emit('change', $event)" />
        <span class="checkmark" :style="'border:1px solid '+color"></span>
    </label>
</template>
<script>
export default {
    props: {
        "label": { type: String, default: "", required:false },
        "modelValue": { default: "" },
        "name": {default: ""},
        "color": {default: ""},
        "value": { type: String, default: undefined }
    },
    computed: {
        isChecked() {
            return this.modelValue === this.value
        }
    },
}
</script>
<style scoped>
.wrapper {
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}
.checkmark {
position: relative;
top: 0;
left: 0;
height: 30px;
display: block;
width: 30px;
border-radius: 50%;
background-color: #fff;
}

.checkmark:after {
content: "";
position: absolute;
display: none;
}
.wrapper input:checked ~ .checkmark:after {
display: block;
}

.wrapper .checkmark:after {
top: 5px;
left: 5px;
width: 18px;
height: 18px;
border-radius: 50%;
background: #009FE3;
}
</style>