<template lang="pug">
  .item-cn.row

    .col-sm-3.product-cn
      picture.img-cn
        img(:src="checkEmptyImg(product.image_url)")
      h3 {{product.name}}
    .col-sm-2
      ul
        li(v-if="isTypeApp === 'pedrosa'") #[translate Ref.] {{product.external_id}}
        li(v-else) #[translate Código]: {{product.external_id}}
        li(v-if="product.quantity_by_packet == 1") {{product.quantity_by_packet}} #[translate unidad por envase]
        li(v-if="product.quantity_by_packet > 1") {{product.quantity_by_packet}} #[translate unidades por envase]
        li(v-if="product.packets_by_package") {{product.packets_by_package}} #[translate envases por paquete]

    .col-sm-1
      a.enlace-pedido(v-if="entries.delivery_note" @click="setDelivery(entries.delivery_note.id)") {{entries.delivery_note.number}}
    .col-sm-1 
      a.enlace-pedido(v-if="entries.sale_order" @click="setOrder(entries.sale_order.id)") {{entries.sale_order.number}}
    .col-sm-2.text-right.precio-und
      p {{entries.price | format-money}} {{product.currency_symbol}}
    .col-sm-1.text-right
      p {{entries.quantity}}
    .col-sm-1.text-right
      p {{entries.measurement_unit}}
    .col-sm-1.text-right
      p {{entries.base | format-money}} {{product.currency_symbol}}
</template>
<script>
export default {
  name: 'PedidoSingleItem',
  props: ['entries', 'billable'],
  data () {
    return {
      'imgDefault': require('@/assets/img/no_disponible.png')
    }
  },
  computed: {
    product () {
      return this.entries.product
    },
    isBillableCenter () {
      return this.billable ? this.$gettext('Si') : this.$gettext('No')
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  created () {
    // console.log(`entries:`, this.entries)
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    setOrder (idp) {
      // Para ir al detalle de la orden
      this.$router.push({name: 'pedidoSingle', params: {id: idp}})
    },
    setDelivery (idd) {
      // Para ir al detalle de la orden
      this.$router.push({name: 'albaranSingle', params: {id: idd}})
    }

  }
}
</script>
<style lang="scss" scoped>
.enlace-pedido{
  cursor: pointer;
}
.item-cn{
  margin:0;
  padding: 20px 0 30px;
  border-bottom: 1px solid #979797;
  color:#4D4F5C;
}
.row{
  display: flex;
  align-items: center;
}

.product-cn{
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 100px;
  font-size: 14px;
}

.img-cn{
  position:absolute;
  top:0;
  bottom:0;
  left:6px;
  width: 78px;
  min-height:58px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: $white;

  img{
    max-width: 100%;
    max-height: 54px;
    height:auto;
  }
}

h3{
  color:#1C2C40;
  font-size: 13px;
  text-transform: uppercase;
  margin:0;
  font-weight: $regular;
  // @include ellipsis();
}

ul{
  list-style: none;
  padding:0;
  font-size: 12px;
}

.und{
  font-size:14px;
  color:#61c844;

  &.noStock{
    color:#ff5466;
  }
}

.isBillable{
  color: $green-true;
}

</style>