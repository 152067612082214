<template>
<div>
  <div class="tw-w-[1200px] tw-m-auto tw-flex tw-items-end tw-mt-[15px] tw-border-b tw-border-[#3E3E3E] tw-pb-[14px]">
    <div class="tw-w-1/2 ">
      <span class="tw-text-[16px]">Encuestas Satisfacción</span>
    </div>
    <div class="tw-w-1/2 tw-flex tw-justify-end">
      <date-picker class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="date" :first-day-of-week="calendar.firstDayOfWeek" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm />
    </div>
  </div>
  <div class="tw-m-auto tw-w-[1200px] tw-mt-[15px]">
    <div v-if="data" class="tw-flex tw-items-center  tw-p-[15px]">
      <div class="tw-w-full tw-max-w-[618px]">
        <span>Nº total de encuestas realizadas: <span class="tw-text-[18px]">{{ data.total_answers }}</span></span>
        <div v-if="data" class="tw-flex tw-mb-[10px] ">
          <div class="tw-w-full tw-max-w-[618px]">
            <p>Nº de comentarios: {{ data.total_comments }} </p>
          </div>    
        </div>
      </div>
      
      <div class="tw-w-full tw-max-w-[528px] tw-flex tw-ml-[24px]">
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#006838]">{{ data.values_totals_pct && data.values_totals_pct[5] ? data.values_totals_pct[5] : 0 | formatNumber }} %</span>
          <img src="@/assets/img/muybuena.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
          <span class="tw-text-14">Muy buena</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#8DC63F]">{{ data.values_totals_pct && data.values_totals_pct[4] ? data.values_totals_pct[4] : 0 | formatNumber }} %</span>
          <img src="@/assets/img/buena.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
          <span class="tw-text-14">Buena</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#FBB040]">{{ data.values_totals_pct && data.values_totals_pct[3] ? data.values_totals_pct[3] : 0 | formatNumber }} %</span>
          <img src="@/assets/img/satisfactoria.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
          <span class="tw-text-14">Satisfactoria</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#E05166]">{{ data.values_totals_pct && data.values_totals_pct[2] ? data.values_totals_pct[2] : 0 | formatNumber }} %</span>
          <img src="@/assets/img/mala.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
          <span class="tw-text-14">Mala</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#CE5560]">{{ data.values_totals_pct && data.values_totals_pct[1] ? data.values_totals_pct[1] : 0 | formatNumber }} %</span>
          <img src="@/assets/img/muymala.svg" class="tw-m-auto tw-mb-[16px] tw-max-w-[66px]" />
          <span class="tw-text-14">Muy mala</span>
        </div>
      </div>
      
    </div>
    <div class="tw-flex tw-p-[15px]" v-for="(item, index) in questionsWithResults" :class="{'tw-bg-[#F3F9FF]': index % 2 === 0}">
      <div class="tw-w-full tw-max-w-[618px]">
        <p>{{ item[0] }}</p>
      </div>
      <div class="tw-w-full tw-max-w-[528px] tw-flex tw-ml-[24px]">
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#006838]">{{ item[1].filter(e => e.value === 5).length > 0 ? item[1].filter(e => e.value === 5)[0].total : 0}}</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#8DC63F]">{{ item[1].filter(e => e.value === 4).length > 0 ? item[1].filter(e => e.value === 4)[0].total : 0}}</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#FBB040]">{{ item[1].filter(e => e.value === 3).length > 0 ? item[1].filter(e => e.value === 3)[0].total : 0}}</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#E05166]">{{ item[1].filter(e => e.value === 2).length > 0 ? item[1].filter(e => e.value === 2)[0].total : 0}}</span>
        </div>
        <div class="tw-w-1/5 tw-flex tw-flex-col tw-text-center tw-justify-center">
          <span class="tw-text-[#CE5560]">{{ item[1].filter(e => e.value === 1).length > 0 ? item[1].filter(e => e.value === 1)[0].total : 0}}</span>
        </div>
      </div>      
    </div>
    
    
    
    
    
  </div>
  <!-- NPS -->
  <div v-if="data" class="tw-m-auto tw-w-[1200px] tw-border-t tw-border-t-[#ccc] tw-border-b tw-border-b-[#ccc] tw-mt-[15px]">
    <div class="tw-w-full tw-pt-[30px] tw-border-b tw-border-b-[#3E3E3E] tw-pb-[14px]">
      <span class="tw-text-[16px]">NPS Recomendación</span>
    </div>
    <div class="tw-flex tw-mb-[10px] tw-items-center  tw-p-[15px]">
      <div class="tw-w-full tw-max-w-[280px] tw-flex tw-items-center">
        <span>Nº total de opiniones: {{ data.nps_total }}</span>
      </div>
      <div class="tw-w-full tw-max-w-[362px] tw-flex tw-ml-[24px]">
        <div class="tw-flex">
          <div class="detractores tw-mr-[20px]">
            <div class="tw-border-b-2 tw-border-[#707070] tw-mb-[10px] tw-flex tw-items-end tw-h-[56px] tw-pb-[10px]">
              <img :src="img.detractoresFace" class="tw-mx-auto" :style="'width:' + getWidthFace(data.detractors_pct,1)" />
            </div>
            <div class="tw-flex tw-flex-col tw-justify-center tw-text-center">
              <img :src="img.detractoresPeople" class="tw-w-[41px] tw-mx-auto" />
              <span class="tw-text-[#FA746E]">Detractores</span>
              <div classs="data tw-text-[18px]">
                <strong>{{ data.detractors_total }}</strong>
                <span> ({{ data.detractors_pct | formatNumber }}%)</span> 
              </div>
            </div>
          </div>
          <div class="promotores tw-text-center tw-ml-[20px]">
            <div class="tw-border-b-2 tw-border-[#707070] tw-mb-[10px] tw-flex tw-items-end tw-h-[56px] tw-pb-[10px]">
              <img :src="img.promotoresFace" class="tw-mx-auto" :style="'width:' + getWidthFace(data.promoters_pct,1)" />
            </div>
            <div class="tw-flex tw-flex-col tw-justify-center tw-text-center">
              <img :src="img.promotoresPeople" class="tw-w-[41px] tw-m-auto"/>
              <span class="tw-text-[#89C445]">Promotores</span>
              <div classs="data tw-text-[18px]">
                <strong> {{ data.promoters_total }}</strong>
                <span> ({{ data.promoters_pct | formatNumber }}%)</span> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-w-full tw-max-w-[310px] tw-flex tw-ml-[120px] tw-items-center">
        <div class="boxGaugeTwoColorsAndGrPromotorScorePercent">
          <chart-gauge-two-colors :percent="data.nps" class="tw-w-[133px]"></chart-gauge-two-colors>
          <div class="gastroRankingPromoterScorePercent">
            <div class="tw-text-[28px] tw-text-center" :class="getColorPercent(data.nps)">
              <span>{{ getPercentGrPromotterPretty(data.nps) }} %</span>
            </div>
             
          </div>
        </div>
        <div class="tw-ml-[15px]">          
          <div class="tw-flex tw-items-center tw-text-[12px]">
            <span :class="getArrow(50, 45)"></span>
            <span> {{ 5 }}</span>
            <span> p vs P.A.</span>
            
          </div>
          <div class="tw-flex tw-items-center tw-text-[12px]">
            <span :class="getArrow(50, 45)"></span>
            <span> {{ 5 }}</span>
            <span> p vs P.A.A.</span>
            
          </div>
      </div>
    </div>
    </div>
  </div>
</div>
</template>
<script>
import appConfig from '@/config-app.js'
import ChartGaugeTwoColors from '@/components/surveys/GaugeTwoColors.vue'
import DatePicker from 'vue2-datepicker'
import SrvSurveys from '@/services/surveys.js'
export default {
  name: 'pedidosComprar',
  components: { ChartGaugeTwoColors, DatePicker },
  data () {
    return {
      data: null,
      'query': '',
      'ordering': '', // Ordenación de producto
      'typeProduct': '', // Si es autorizado o no.
      'filterByType': '', // si es outlet, eco o más consumido.
      'cat': '', // Categorías de producto.
      'aCat': [], // Guardamos todas las categorías.
      img:{        
        detractoresFace: require('@/assets/img/detractoresCara.svg'),
        promotoresFace: require('@/assets/img/promotoresCara.svg'),
        detractoresPeople: require('@/assets/img/detractoresPeople.svg'),
        promotoresPeople: require('@/assets/img/promotoresPeople.svg'),
        arrowNegative: require('@/assets/img/flecha_down.svg'),
        arrowPositive: require('@/assets/img/flecha_up.svg')
      },
      'calendar': {
        'before': this.$moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().add({'years': -1}).format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().add({'years': -1}).format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY', // 'MMM YYYY'
        'firstDayOfWeek': 1
      }
    }
  },
  computed: {
    questionsWithResults(){
      if (this.data) 
        return Object.entries(this.data.question_results)
      return []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after} - ${this.calendar.before}`)
        this.currentPage = 1
        this.getData()
      }
    },
    async getData(){
      const res = await SrvSurveys.getResults(1,this.calendar.before, this.calendar.after)
      if (res.status === 200) {
        this.data = res.data
      }
    },
    getArrow(data, data_tend){     
      if( data > data_tend){
        return 'tw-w-0 tw-h-0 tw-border-b-[9px] tw-border-b-[#3cc480] tw-border-l-[6px] tw-border-l-[transparent] tw-border-r-[6px] tw-border-r-[transparent]'
      } else if(data === data_tend){
        return 'tw-w-[8px] tw-h-[8px] tw-bg-[#F5A623]'
      } else if(data < data_tend){
        return 'tw-w-0 tw-h-0 tw-border-t-[9px] tw-border-t-[#E8523F] tw-border-l-[6px] tw-border-l-[transparent] tw-border-r-[6px] tw-border-r-[transparent]'
      }
      return ''
    },
    getImgArrow(valor){
        if(valor > 0){
          return this.img.arrowPositive
        } else{
          return this.img.arrowNegative
        }
      },
    getPercentGrPromotterPretty(gps){
        let result = this.$options.filters.formatNumber(gps)
        if (result > 0){
          result = '+ '+ result
        }
        return result
      },
      getColorPercent(valor){
        if(valor <= 0.50){
            return 'tw-text-[#D61A23]'
        } else if (valor < 25.0) {
            return 'tw-text-[#F5A623]'
        } else {
            return 'tw-text-[#89C445]'
        }
      },
    getWidthFace(num, index){
        let widthFace = 0
        let divi = 1.2

        if(index !== 0){
          divi = 1.5
        }
        widthFace = num / divi < 10 ? 10 : num / divi

        return widthFace + '%'
      },
  },
  watch: {
  }
}
</script>