<template lang="pug">
    .gaugeTwoColors
        svg(version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 202.7 140.5" style="enable-background:new 0 0 202.7 140.5;" xml:space="preserve")
            text(class="cls-6" transform="translate(97.98 12.04)" fill="#43425D") 0
            text(class="cls-6" transform="translate(3.98 135.04)" fill="#43425D" ) -100
            text(class="cls-6" transform="translate(160.31 135.04)" fill="#43425D") +100
            g(id="Grupo_10092" transform="translate(-5020.652 -3840.514)")
                g(id="Grupo_10091" transform="translate(0 2)")
                    path(id="Unión_33" class="st0" fill="#F93549" d="M5037.6,3953.9c-9,0-16.3-7.3-16.4-16.3c0-1.3,0.1-2.5,0.4-3.8c4.7-19.2,14.6-36.8,28.7-50.6l0,0.1c18.9-17.6,43.6-27.7,69.5-28.2v32.7c-17.6,0.6-34.3,7.5-47.2,19.4l0,0c-9.4,9.4-16,21.3-19.2,34.2C5051.7,3948.7,5045.1,3953.9,5037.6,3953.9L5037.6,3953.9z")
                    path(class="st1" fill="#89C445" d="M5222.9,3933.7c-4.8-19.7-15.2-37.7-29.9-51.6l-0.1,0.1c-8.8-7.9-18.7-14.2-29.4-18.8l-13.7,29.7c0,0,0,0,0,0c7.5,3.1,14.6,7.4,20.8,13l0.1-0.1c10,9.6,17.1,21.9,20.4,35.4l0,0c2.1,8.8,11,14.2,19.7,12.1S5225,3942.5,5222.9,3933.7z")
                    path(class="st2" fill="#FCAE19" d="M5163.5,3863.4C5163.5,3863.4,5163.5,3863.4,5163.5,3863.4c-1.5-0.7-3.1-1.3-4.6-1.8c-0.3-0.1-0.6-0.2-0.9-0.3c-1.2-0.4-2.5-0.9-3.7-1.3c-0.4-0.1-0.9-0.3-1.3-0.4c-1-0.3-2.1-0.6-3.1-0.9c-0.6-0.2-1.3-0.3-1.9-0.5c-0.7-0.2-1.5-0.4-2.2-0.5c-0.9-0.2-1.9-0.4-2.8-0.6c-0.5-0.1-1-0.2-1.5-0.3c-1.2-0.2-2.3-0.4-3.5-0.6c-0.4-0.1-0.8-0.1-1.2-0.2c-1.3-0.2-2.5-0.3-3.8-0.5c-0.3,0-0.6-0.1-0.9-0.1c-1.4-0.1-2.7-0.2-4.1-0.3c-0.2,0-0.4,0-0.5,0c-1.5-0.1-3-0.1-4.5-0.1v32.7c1.1,0,2.3,0,3.4,0.1c0.3,0,0.6,0,0.8,0.1c0.9,0.1,1.7,0.1,2.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0.9,0.1,1.8,0.2,2.6,0.3c0.2,0,0.4,0.1,0.7,0.1c0.9,0.1,1.9,0.3,2.8,0.5c0.2,0,0.3,0.1,0.5,0.1c1,0.2,2,0.4,2.9,0.7c0.1,0,0.2,0.1,0.3,0.1c1,0.3,2.1,0.5,3.1,0.9c0,0,0.1,0,0.1,0c1.1,0.3,2.1,0.7,3.2,1c0,0,0,0,0,0c1.1,0.4,2.2,0.8,3.2,1.2c0,0,0,0,0,0L5163.5,3863.4z")
                
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" data-min="-100" data-max="-90" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" id="Línea_657" x1="5045" y1="3954.5" x2="5121" y2="3954.5")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" data-min="-89" data-max="-79" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" id="Línea_614-2" x1="5121" y1="3954.5" x2="5045.3" y2="3940.9")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" data-min="-78" data-max="-69" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" id="Línea_618-2" x1="5121" y1="3954.5" x2="5048.8" y2="3928")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="-68" data-max="-59" stroke-linecap="round" stroke-miterlimit="10" id="Línea_620-2" x1="5121" y1="3954.5" x2="5054.4" y2="3915.9")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="-58" data-max="-49" stroke-linecap="round" stroke-miterlimit="10" id="Línea_622-2" x1="5121" y1="3954.5" x2="5062.1" y2="3904.9")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="-48" data-max="-39" stroke-linecap="round" stroke-miterlimit="10" id="Línea_624-2" x1="5121" y1="3954.5" x2="5071.6" y2="3895.5")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="-38" data-max="-29" stroke-linecap="round" stroke-miterlimit="10" id="Línea_626-2" x1="5121" y1="3954.5" x2="5082.5" y2="3887.8")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="-28" data-max="-16" stroke-linecap="round" stroke-miterlimit="10" id="Línea_628-2" x1="5121" y1="3954.5" x2="5094.6" y2="3882.1")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="-15" data-max="-01" stroke-linecap="round" stroke-miterlimit="10" id="Línea_630-2" x1="5121" y1="3954.5" x2="5107.6" y2="3878.7")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="0" data-max="1" stroke-linecap="round" stroke-miterlimit="10" id="Línea_631" x1="5121.4" y1="3954.5" x2="5121.4" y2="3877.5")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="2" data-max="10" stroke-linecap="round" stroke-miterlimit="10" id="Línea_630" x1="5121.5" y1="3954.5" x2="5134.9" y2="3878.7")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="11" data-max="25" stroke-linecap="round" stroke-miterlimit="10" id="Línea_628" x1="5121.5" y1="3954.5" x2="5147.9" y2="3882.1")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="26" data-max="32" stroke-linecap="round" stroke-miterlimit="10" id="Línea_626" x1="5121.5" y1="3954.5" x2="5160" y2="3887.8")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="33" data-max="40" stroke-linecap="round" stroke-miterlimit="10" id="Línea_624" x1="5121.5" y1="3954.5" x2="5170.9" y2="3895.5")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="41" data-max="53" stroke-linecap="round" stroke-miterlimit="10" id="Línea_622" x1="5121.5" y1="3954.5" x2="5180.4" y2="3904.9")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="54" data-max="67" stroke-linecap="round" stroke-miterlimit="10" id="Línea_620" x1="5121.5" y1="3954.5" x2="5188.1" y2="3915.9")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="68" data-max="78" stroke-linecap="round" stroke-miterlimit="10"  id="Línea_618" x1="5121.5" y1="3954.5" x2="5193.7" y2="3928")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="79" data-max="89" stroke-linecap="round" stroke-miterlimit="10" id="Línea_614" x1="5121.5" y1="3954.5" x2="5197.2" y2="3940.9")
                line(style="display:none"  class="lineGaugeChartThree" fill="none" stroke="#707070" stroke-width="5" data-min="90" data-max="100" stroke-linecap="round" stroke-miterlimit="10" id="Línea_656" x1="5121.5" y1="3954.5" x2="5197.5" y2="3954.5")
       
</template>
<script>

export default {
  name: "chartGaugeThreeColors",
  props: ['percent'],
  data(){
      return {
          green: 0,
      }            
  },
  created(){
    setTimeout(()=> {
    this.setValue(this.percent);

    },200)
  },
  methods: {
    setValue: function(x) {
        let datoAjustado = Math.round(x)
        let elements = document.querySelectorAll(".lineGaugeChartThree")
        for(let ele in elements){
            if(ele < 19){
            if(datoAjustado >= elements[ele].getAttribute('data-min') && datoAjustado <= elements[ele].getAttribute('data-max')){
                elements[ele].setAttribute("style","")
            }
            }
        }
    }
  }
}
</script>
