<template lang="pug">
  .banner-sidebar
    div(v-if="typeEcom === 'prisma'")
      <div class="adplugg-tag" data-adplugg-zone="lateral1_prisma_c_cerrado"></div>
      <div class="adplugg-tag" data-adplugg-zone="lateral2_prisma_c_cerrado"></div>
      <div class="adplugg-tag" data-adplugg-zone="lateral3_prisma_c_cerrado"></div>     

    

    
</template>

<script>
export default {
  name: 'BannerSidebar',
  props: [],
  data () {
    return {
      img: {
        banner1: require('@/assets/img/banner/banner_col_250x358.jpg'),
        banner2: require('@/assets/img/banner/banner_col_250x208.jpg'),
        banner3: require('@/assets/img/banner/banner_col_250x300.jpg')
      }
    }
  },
  computed: {
    typeEcom () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  mounted () {
    const ref = this
    setTimeout(() => {
      window.$('.banner-sidebar a[href*="#"]').on({
        click: function (e) {
          e.preventDefault(e)
          const url = window.$(this).attr('href').replace('#', '')
          // console.log('-----' + url)
          ref.setCategory(url)
        }
      })
    }, 2000)
  },
  methods: {
    setCategory (v) {
      this.$emit('toSetCategory', v)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-sidebar{
  p{
    text-align: center;
    padding-top: 12px;
    margin: 0;
  }

  a, .adplugg-tag{
    display: block;
    width: 250px;
    margin: 0 auto 24px;

  }
}
</style>
