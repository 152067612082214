// Importamos mutaciones
import * as types from '@/store/mutation-types'
// importamos servicios
import SrvCatalog from '@/services/catalog'
export default {
    computed: {
        frequenciesWeek() {
            return ['Lunes','Martes','Miércoles','Jueves','Viernes','Sádado','Domingo']
        },
        frequenciesMonth() {
            return ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28']
        },
        frequencies () {
            return [{id: '1m', value: 'Mensual'}, {id: '2m', value: 'Bimensual'}, {id: '3m', value: 'Trimestral'}, {id: '4m', value: 'Cuatrimestral'}, {id: '6m', value: 'Semestral'}]
        },
        modeOrderRecurrent() {            
            return this.$store.getters.getIsRecurrentOrder
        },
    },
    methods: {
        async replicar  ({hasClosePopup = false, center}) {
            
            this.$store.dispatch('setCenter', center)
            await SrvCatalog.deleteCart()
            
            this.enviando = true
            if (this.enviando) {
              await Promise.all(
                this.aProduct.map(async item => {
                  let obj = {
                    'client': this.client.id,
                    'product': item.product.id,
                    'quantity': item.quantity,
                    'description': '',
                    'billable': false
                  }
                  await this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: obj, 'hide': true, notGetCart: true})
                })
              )
      
              window.localStorage.setItem('recurringOrder', JSON.stringify({frequency: this.frequency, day: this.day, id: this.idOrderEdit}))
              if (hasClosePopup) {
                this.$refs.close.click()
              }
              await this.$store.dispatch('getCart')
              this.$router.push({name: 'cartList'})
              this.enviando = false
            }
            
          }, 
    },
}