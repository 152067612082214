// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  sendFormPrivate (idProduct, text) {
    return new Promise((resolve, reject) => {
      // mostramos Loading

      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''

      Stores.commit(types.PENDING)

      const datos = {
        'product': {'id': idProduct},
        'text': text
      }

      if (idClient) {
        datos.client = {'id': idClient}
      }

      if (idCenter) {
        datos.center = {'id': idCenter}
      }

      let query = `/quotation_request/`
      // console.log(`Query busqueda producto: ${query}`)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.post(query, datos)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  sendFormPublic (datos) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let query = `/public_quotation_request/`
      // console.log(`Query busqueda producto: ${query}`)

      // Eliminamos la llamada con la autorización del token.
      delete axios.defaults.headers.common['Authorization']
      axios.post(query, datos)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getProvinces () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // Eliminamos la llamada con la autorización del token.
      delete axios.defaults.headers.common['Authorization']
      axios.get(`/request_regions/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
