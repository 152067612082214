<template lang="pug">
  .homeListadoDetalle
    .container(v-if="messageError")
      .row
        p(class="alert alert-warning fade in" :class="{'alert-success': messageErrorCode === 1}" role="alert") {{messageError}}  
    .container
      .row.grid-head
        .col-sm-3
          p #[translate Descripción]
        .col-sm-3
          p #[translate Código]
        .col-sm-2
          p.text-right #[translate Precio unidad]
        .col-sm-1
          p.text-center #[translate Cantidad]
        .col-sm-1
          p.text-center #[translate Descuento]
        .col-sm-1(v-if="user.can_validate_offers")
          p.text-right #[translate Margen]
        div(:class="{'col-sm-1': user.can_validate_offers, 'col-sm-2': !user.can_validate_offers}")
          p.text-right #[translate Total]

    .grid-cn
      bk-item(v-for="item in aProduct" :key="item.id" :entries="item" :billable="isFacturable" :type="type" :fecha="fecha" :currency_symbol="currency_symbol")

    .container.box-3
      .row
        .col-sm-8.obser-cn
          p(v-if="details")
            strong #[translate Observaciones]:
            small {{details}}
        .col-sm-4
          ul
            li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{currency_symbol}}]
            li #[translate IVA]: #[small {{tax | format-money}} {{currency_symbol}}]
            li #[translate TOTAL] #[small {{total | format-money}} {{currency_symbol}}]

    .container.box-4
      .row
        .col-sm-8.text-right.pull-right(v-if="!$store.getters.getEditOrderPending")
            p(v-if="user.can_validate_offers && type !== 'validados'")
                button.btn.btn-cancel(class="!tw-bg-[#E82C2B] !tw-text-white !tw-border-[#E82C2B]" @click="anular()" :class="{'btn-send': enviando}")
                  span(v-if="!enviando") #[translate Anular]
                  span(v-if="enviando") #[translate Anulando]
                
                button.btn.btn-action(class="!tw-bg-[#F49700] !tw-text-white tw-mx-2" @click="editOferta" :class="{'btn-send': enviando}") #[translate Editar]                  
                button.btn.btn-action(class="!tw-bg-[#1D9240] !tw-text-white" @click="validar()" :class="{'btn-send': enviando}")
                  span(v-if="!enviando") #[translate Validar]
                  span(v-if="enviando") #[translate Validando]
            p(v-if="type === 'validados'")
              button.btn.btn-action(@click="goOferta") #[translate Ver Oferta]
            
</template>

<script>
import BkItem from '@/components/validar-ofertas/Home-item-detalle.vue'

import SrvValidarOfertas from '@/services/validar-ofertas'
import * as types from '@/store/mutation-types'
import SrvCenter from '@/services/center'

export default {
  name: 'ValidarPedidoHomeListadoDetalle',
  props: ['id', 'type', 'showDetalle'],
  components: { BkItem },
  data () {
    return {
      number: null,
      fecha: null,
      client: null,
      center: null,
      lead: null,
      state: null,
      state_values: null,
      print_format: null,
      base: null,
      tax: null,
      total: null,
      details: null, // descripción
      numItems: null,
      billable_center: null, // Si es facturable el centro.
      currency_symbol: null, // moneda
      messageError: null,
      messageErrorCode: null,
      aProduct: [],
      enviando: false
    }
  },
  created () {
    if (this.showDetalle) {
      this.getOferta()
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    isFacturable () {
      return this.billable_center
    }
  },
  methods: {
    getOferta () {
      SrvValidarOfertas.getOferta(this.id, this.type)
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            this.number = data.number
            this.fecha = data.delivery_datetime
            this.client = data.client
            this.center = data.center
            this.lead = data.lead
            this.state = data.state
            this.state_values = data.state_values
            this.base = data.base
            this.tax = data.tax_total
            this.total = data.total
            this.aProduct = data.entries
            this.numItems = this.aProduct.length
            this.details = data.details
            this.billable_center = data.billable_center
            this.currency_symbol = data.currency_symbol
            this.print_format = data.print_format
            // console.log(`number `, res)
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    goOferta () {
      // console.log('Order seleccionada: ', this.id)
      this.$router.push({name: 'validarOfertaSingle', params: {type: this.type, id: this.id}})
    },
    async editOferta () {
      if (!this.enviando) {
        this.enviando = true
        const offerEditing = {id: this.id, number: this.number, print_format: this.print_format, details: this.details}
        window.localStorage.setItem('EDIT_OFFER_PENDING', JSON.stringify(offerEditing))        
        this.$store.commit(types.EDIT_OFFER_PENDING, offerEditing)
        await this.$store.dispatch('deleteCart')
        if (this.lead) {
          // console.log('--------', item)
          const email = this.lead.email
          if (email) {
            // Enviamos a Store Lead los datos del Lead
            this.$store.dispatch('setLead', this.lead)
            // Lo pasamos a su State
            this.$store.commit('SET_LEAD')
            this.$router.push({'name': 'leadOferta'})
          }
        } else {
          this.$store.dispatch('setClient', this.client)
          this.$store.commit('SET_CLIENT')
          if (this.center) {
            const { status, data } = await SrvCenter.getCenter(this.center.id)
            if (status === 200) {
              this.$store.dispatch('setCenter', data)
              this.$store.commit('SET_CENTER')
            }
          }
        }
        await Promise.all(this.aProduct.map( async (item, index) => {
          let obj = {
            'client': this.client?.id ? this.client.id : '',
            'product': item.product.id,
            'quantity': item.quantity,
            'discount': item.discount,
            'description': '',
            'billable': false
          }
          if (this.lead) {
            obj.lead = this.lead.id
          }
          await this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: obj, 'hide': true})
        }))
        if (this.lead) {
          this.$router.push({name: 'leadOfertaCartList'})
        } else {
          this.$router.push({name: 'cartList'})
        }
        this.enviando = false

      }
    },
    async anular () {
      if (!this.enviando) {
        this.enviando = true
        try{
          const res = await SrvValidarOfertas.validarAnular(this.id, 'True')
          if (res.status === 200) {
            this.messageError = this.$gettext('Oferta anulada.')
            this.messageErrorCode = 1
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.messageErrorCode = 2
          }
          this.$store.dispatch('updateValidableOffers')
          this.enviando = false
        } catch (e) {
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          this.messageErrorCode = 2
          this.enviando = false
        } 
      }
    },
    async validar () {
      if (!this.enviando) {
        this.enviando = true
        try{
          const res = await SrvValidarOfertas.validarAnular(this.id, 'false')
          if (res.status === 200) {
            this.messageError = this.$gettext('Oferta validada.')
            this.messageErrorCode = 1
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.messageErrorCode = 2
          }
          this.$store.dispatch('updateValidableOffers')
          this.enviando = false
        } catch (error) {
          if (error.data.code === '100') {
            this.messageError = this.$gettext('La oferta pasa a estar pendiente de validación.')
          } else if (error.data.code === '102') {
            this.messageError = error.data.message
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          this.messageErrorCode = 2
          window.scrollTo(0, 0)
          this.enviando = false
        }        
      }
    }
  },
  watch: {
    showDetalle () {
      if (this.showDetalle) {
        this.getOferta()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .homeListadoDetalle{
    max-width: 1100px;
    margin: 20px auto 0;
    padding-bottom: 50px;
    border-bottom: 1px solid #1C2C40;
  }

  .homeItemDetalle{

    &:nth-child(odd){
      background-color: #F5F7F9;
    }

    &:last-child{

    }
  }

  .box-3{
    border-bottom: 1px solid rgba(#545454, .46);
    padding-bottom:20px;
    margin:12px 0 20px;

    ul{
      padding-left: 0;
      list-style: none;
    }

    li{
      color: #5b5d5e;
      font-size: 14px;
      margin-bottom: 2px;

      small{
        font-size: 14px;
        float: right;
      }

      &:last-child{
        font-weight: bold;
      }
    }

    .obser-cn{
      strong{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
      }

      small{
        display: inline-block;
        font-size: 14px;
        width:70%;
        height: 80px;
      }
    }
  }

  .box-4{
    .btn{
      transform: translateX(15px);
    }
  }


</style>
